import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';

const labels = ['Aperto', 'Cancellato', 'Risolto'];
const backgroundColor = ['gold', 'cornflowerblue', 'darkslategray'];
const options = {
  maintainAspectRatio: false,
  responsive: false,
};

const ChartStatus = () => {
  const [tickets, setTickets] = useState([]);
  const [data, setData] = useState({
    datasets: [{
      data: [0, 0, 0],
      backgroundColor: backgroundColor
    }],
    labels: labels
  });





  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    axios.get(`${serverUrl}/tickets/`)
      .then(res => {
        setTickets(res.data);
        let open = 0;
        let progress = 0;
        let Risolto = 0;
        let cancellati=0;
        console.log(res.data);
        res.data.forEach(ticket => {
          switch (ticket.status.toLowerCase()) {
            case 'open':
              open++;
              break;
            case 'risolto':
              Risolto++;
              break;
            case 'cancellato':
              cancellati++;
              break;

            default:
              break;
          }
  
          // Controlla anche lo stato nella storia del ticket solo se storia non è vuoto
          if (ticket.storia.length > 0) {
            const isInProgress = ticket.storia.some((event) => event.status === 'In Progress');
            if (isInProgress) {
              progress++;
            }
          }
        });
  
        setData({
          ...data,
          datasets: [{
            ...data.datasets[0],
            data: [open, cancellati, Risolto]
          }]
        });
      })
      .catch(error => console.log(error));
  }, []);
  
  return (
    <div style={{ backgroundColor: 'lightgray', height:350 }}>
      <Doughnut
        data={data}
        options={options}
        height={350}
        width={500} />
    </div>
  );
};

export default ChartStatus;
