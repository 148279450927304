

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InfoTicket from './dialogInfoTicket';


const TableCellWithCircles = ({ ticketsAperti }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [ticketsData, setTicketsData] = useState({});
  const [hoveredTicketId, setHoveredTicketId] = useState(null);
  const navigate= useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const fetchTicketsDetails = async () => {
      const detailsPromises = ticketsAperti.map(async ticketId => {
        try {
          const response = await axios.get(`${serverUrl}/tickets/leggi/${ticketId}`);
          return { id: ticketId, data: response.data };
        } catch (error) {
          console.error(`Error fetching details for ticket ${ticketId}:`, error);
          return null;
        }
      });

      const ticketDetails = await Promise.all(detailsPromises);
      const detailsMap = ticketDetails.reduce((acc, { id, data }) => {
        if (data) acc[id] = data;
        return acc;
      }, {});

      setTicketsData(detailsMap);
    };

    fetchTicketsDetails();
  }, [serverUrl, ticketsAperti]);

  const handleMouseEnter = ticketId => {
    setHoveredTicketId(ticketId);
  };

  const handleMouseLeave = () => {
   setHoveredTicketId(null);
  };



   return (
    <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>
      <div style={{ position: 'relative' }}>
        {ticketsAperti.map((ticketId, index) => (
          <div
            key={ticketId}
            style={{
              display: 'inline-block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'red',
              margin: '0 2px',
              cursor: 'pointer',
            }}
         
            onMouseEnter={() => handleMouseEnter(ticketId)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
        {hoveredTicketId && ticketsData[hoveredTicketId] && (
          <p
            style={{
              position: 'absolute',
              top: '25px',
              left: '50%', // Spostamento a destra rispetto al centro del contenitore
              transform: 'translateX(-50%)', // Centratura rispetto alla larghezza del contenitore
              width: '200px', // Larghezza personalizzabile
              textAlign: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            {ticketsData[hoveredTicketId].description}
          </p>
        )}
        {openDialog && hoveredTicketId && ticketsData[hoveredTicketId] && ( // Renderizza InfoTicket solo se openDialog è true e i dati del ticket sono disponibili
          <InfoTicket open={openDialog} onClose={handleCloseDialog} ticket={ticketsData[hoveredTicketId]} />
        )}
      </div>
    </td>
  );
};











export default TableCellWithCircles;