
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from "react-redux";
const {  REACT_APP_SERVER_URL } = process.env;

const FormStoria = ({ open, onClose, ticket }) => {
  const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
  const [email, setEmail] = useState('');
  const [autoSend, setAutoSend] = useState('');
  const [autoTelegram, setAutoTelegram] = useState('');


  const { id } = useParams();
  const idTicket = id;




  useEffect(() => {
    const leggiConf = async () => {
      try {
        const response = await axios.get(`${serverUrl}/utils/read`);
        setEmail(response.data[0].email);
        setAutoSend(response.data[0].autoSend);
        setAutoTelegram(response.data[0].autoTelegram);
        console.log(response.data[0].email);
        console.log(response.data[0].autoSend);
      } catch (error) {
        console.error(error);
      }
    };
  
    leggiConf();
  }, [serverUrl]);
  

//===================================================
const showAlert = () => {
  //const msg = document.getElementById("description").value;
  const msg =  ticket.description;
  //alert(`Contenuto del campo: ${msg} ==> RIATTIVARE INVIO A TELEGRAM !`);
//  sendMessageToTelegram(); // Mostra un alert con il contenuto dell'input
};

  // Gestisce il cambiamento dell'input email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

// effetto per gestire l'invio automatico della mail se autoSend è true: 

// useEffect(() => {
//   if (autoSend) {
//     // Invia automaticamente l'email
//       inviaEmail();
//   }
// }, [autoSend]);
  
  //==============================================================================
 // Gestione invio mail
const inviaEmail = () => {
  const destinatario1 = email;
  let oggetto1 = '';
  let testo2 = '';
  let testo3 = '';

  if (ticket.status === 'open') oggetto1 = 'Apertura nuovo ticket';
  if (ticket.status === 'Risolto') oggetto1 = 'Chiusura ticket';
  if (ticket.status === 'In Progress') oggetto1 = 'Aggiornamento stato ticket';

  const testo1 = `Progetto Asite- Informazioni sul ticket
Tipo: ${ticket.type}, stato: ${ticket.status}.
Data apertura: ${ticket.data}
Data scadenza: ${ticket.scade}
Riferito ad asset: ${ticket.codice}
Matricola: ${ticket.targa}
Elemento controllato: ${ticket.title}
Problema riscontrato: ${ticket.description}
Operatore: ${ticket.proprietario}`;

  if (ticket && ticket.storia.length > 0) {
    testo2 = '\n\n\nStoria interventi\n';
    ticket.storia.forEach((ele) => {
      testo3 += `Data modifica: ${ele.data}
Operatore: ${ele.proprietario}
Descrizione intervento: ${ele.descrizione}\n\n`;
    });
  }

  let testoMail = testo1 + testo2 + testo3;
  console.log(destinatario1);
  console.log(oggetto1);
  console.log(testoMail);

  if (!destinatario1 || !oggetto1 || !testoMail) {
    alert('Assicurati di compilare tutti i campi necessari alla mail.');
    return;
  }

  axios.post(`${serverUrl}/tickets/invia-email`, {
    destinatario: destinatario1,
    oggetto: oggetto1,
    testo: testoMail,
  })
  .then(response => {
    console.log(response.data);
    console.log(response.status);
    if (response.status === 200) {
      alert('Mail inviata al destinatario');
    }
  })
  .catch(error => {
    console.error(error);
  });
};


  // test invio Telegram
  // const sendMessageToTelegram = async () => {
  //   const TelegramBotToken = REACT_APP_TELEGRAM_BOT_TOKEN;
  //   const apiUrl = `https://api.telegram.org/bot${TelegramBotToken}/sendMessage`;
  //  // const msg =  document.getElementById("description").value;
  //   const msg =  ticket.description;
  //   console.log(msg);

  //   try {
  //     const response = await axios.post(apiUrl, {
  //       chat_id: REACT_APP_TELEGRAM_CHAT_ID,
  //       text: msg
  //     });

  //     if (response.data.ok) {
  //       console.log('Messaggio inviato con successo a Telegram');
  //       alert ('Messaggio inviato al canale Telegram: Ticket-iotalab')
  //     } else {
  //       console.error('Errore nell\'invio del messaggio a Telegram');
  //     }
  //   } catch (error) {
  //     console.error('Si è verificato un errore:', error);
  //   }
  // };
//======================================

  const [formValues, setFormValues] = useState({
    proprietario: ticket.proprietario,
    description: "",
    status: "open",
    priority: ticket.priority,
    title: ticket.title,
    image: ticket.image,
    foto: ticket.foto
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    const newData = new Date().toISOString();
    alert(`Data: ${newData}, Proprietario: ${userInfo.nome}, Descrizione: ${formValues.description}, Stato: ${formValues.status}, Priorità: ${formValues.priority}`);
    
    const updatedTicket = {
      ...ticket,
      data: newData,
      proprietario: userInfo.nome,
      description: formValues.description,
      status: formValues.status,
      priority: formValues.priority,
      image: formValues.image,
      foto: formValues.foto
    };

   // alert(formValues.status);

    if (formValues.status === "Risolto") {
      const urlGet = `${serverUrl}/tickets/close/${updatedTicket.idTicket}`;
      axios.post(urlGet)
        .then(function (response) {
          console.log(response);
        //  alert('Aggiornamento eseguito: TICKET CHIUSO');
        })
        .catch(function (error) {
          console.error(error);
        });

      const urlClose = `${serverUrl}/check/removeTicket/${updatedTicket.idTicket}`;
      axios.delete(urlClose)
        .then(function (response) {
          console.log(response);
       //   alert('Checklist aggiornata!');
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    axios.post(`${serverUrl}/tickets/update2/${idTicket}`, updatedTicket)
      .then(function (response) {
        console.log(response);
        alert('Aggiornamento eseguito');
      })
      .catch(function (error) {
        console.error(error);
      });
    if (autoSend) {inviaEmail();}
    onClose();
  };



  const handleSubmit2 = () => {
    const newData = new Date().toISOString();
    alert(`Data: ${newData}, Proprietario: ${userInfo.nome}, Descrizione: ${formValues.description}, Stato: ${formValues.status}, Priorità: ${formValues.priority}`);
    
    const updatedTicket = {
      ...ticket,
      data: newData,
      proprietario: userInfo.nome,
      description: formValues.description,
      status: formValues.status,
      priority: formValues.priority,
      image: formValues.image,
      foto: formValues.foto
    };

   // alert(formValues.status);

    if (formValues.status === "Risolto") {
      const urlGet = `${serverUrl}/tickets/close/${updatedTicket.idTicket}`;
      axios.post(urlGet)
        .then(function (response) {
          console.log(response);
        //  alert('Aggiornamento eseguito: TICKET CHIUSO');
        })
        .catch(function (error) {
          console.error(error);
        });

      const urlClose = `${serverUrl}/check/removeTicket/${updatedTicket.idTicket}`;
      axios.delete(urlClose)
        .then(function (response) {
          console.log(response);
       //   alert('Checklist aggiornata!');
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    axios.post(`${serverUrl}/tickets/update2/${idTicket}`, updatedTicket)
      .then(function (response) {
        console.log(response);
        alert('Aggiornamento eseguito');
      })
      .catch(function (error) {
        console.error(error);
      });
     inviaEmail();
    onClose();
  };

  const { userInfo } = useSelector((state) => state.auth);
 // alert(autoSend);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ '& .MuiDialog-paper': { width: '50%' } }}
    >
      <DialogTitle>Aggiornamento ticket</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          <strong>Data:</strong> {new Date().toLocaleString()}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Proprietario:</strong> {userInfo.nome}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Elemento controllato:</strong> {ticket.title}
        </Typography>
        <TextField
          margin="dense"
          label="Descrizione problema"
          name="description"
          value={formValues.description}
          onChange={handleChange}
          fullWidth
        />
        <InputLabel id="status-label">Stato</InputLabel>
        <Select
          labelId="status-label"
          label="Stato"
          name="status"
          value={formValues.status}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="open">Aperto</MenuItem>
          <MenuItem value="In Progress">In lavorazione</MenuItem>
          <MenuItem value="Risolto">Chiuso</MenuItem>
        </Select>
        <InputLabel id="priority-label">Priorità</InputLabel>
        <Select
          labelId="priority-label"
          label="Priorità"
          name="priority"
          value={formValues.priority}
          onChange={handleChange}
          fullWidth
        >
          <MenuItem value="Alta">Alta</MenuItem>
          <MenuItem value="Media">Media</MenuItem>
          <MenuItem value="Bassa">Bassa</MenuItem>
        </Select>
        {ticket.foto && (
          <Grid item xs={12} sm={4}>
            <img
              src={`data:image/jpeg;base64,${ticket.foto}`}
              alt="Immagine"
              width={300}
              height={300}
              style={{ objectFit: 'cover' }}
            />
          </Grid>
        )}
  
        <Grid container spacing={2} style={{ marginTop: '1px' }}>
          {autoSend === false ? (
            <Grid item xs={12} sm={12}>
              <TextField
                label="Email destinatario"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ) : null}
{/*   
          {autoTelegram === false ? (
            <Grid item xs={12}>
              <Button variant="outlined" color="secondary" fullWidth onClick={showAlert}>
                Invia messaggio Telegram
              </Button>
            </Grid>
          ) : null} */}
  
        {/* Mostra solo il pulsante "Invia" quando autoSend è false */}
        {autoSend === true ? (
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Invia
            </Button>
          </Grid>
        ) : null}

        {/* Mostra solo il pulsante "Salva modifiche e invia email" quando autoSend è true */}
        {autoSend === false ? (
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit2}
            >
              Salva modifiche e invia email
            </Button>
          </Grid>
        ) : null}
      </Grid>
      </DialogContent>
      <DialogActions>
      {/* Allineamento con pulsanti */}
      <Grid container spacing={2} >
        <Grid item xs={12} sm={4} sx={{ marginLeft: '18px' }}>
          {/* Pulsante Annulla */}
          <Button variant="contained" color="primary" onClick={onClose}>
            Annulla
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
    </Dialog>
  );
}  
  


export default FormStoria;
