import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material'; // Importa i componenti necessari da Material-UI
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

const priorities = ['Alta', 'Media', 'Bassa'];
const statuses = ['Open', 'In Progress', 'Risolto'];
const types = ['checklist', 'segnalazione',];








const CreateTicket = () => {
    const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
    const [selectedDate, handleDateChange] = useState(new Date());
    const [listaAsset, setListaAsset] = useState([]);
    const [blocchi, setBlocchi] = useState([]); // lista controlli per un dato asset
    const [codice, setCodice] = useState();    // asset
    const [data, setData] = useState(new Date().toLocaleString()); // Imposta la data odierna come valore predefinito
    const [title, setTitle] = useState();  // tipo controllo
    const [progressivo, setProgressivo] = useState(0);
    const [targa, setTarga] = useState();       // matricola asset
    const [description, setDescription] = useState("");
    const [projectName, setProjectName] = useState("Manutenzione Asite");
    const [assignee, setAssignee] = useState("");
    const [priority, setPriority] = useState("");
    const [status, setStatus] = useState("open");
    const [type, setType] = useState("segnalazione");
    const [proprietario, setProprietario] = useState();
    const [projects, setProjects] = useState([]);
    const [image, setImage] = useState('');
    const [foto, setFoto] = useState('');
    const { userInfo } = useSelector((state) => state.auth);
    const [selectedMatricola, setSelectedMatricola] = useState('');
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [alta, setAlta]=useState();
    const [media, setMedia]=useState();
    const [bassa, setBassa]=useState();
    const [email, setEmail] = useState('');
    const [autoSend, setAutoSend] = useState(false);
    const [autoTelegram, setAutoTelegram] = useState(false);
    const [mostraCancellati, setMostraCancellati] = useState(false);
    const [bloccoMezzo, setBloccoMezzo] = useState(false);

    // alert (userInfo.nome);


    const fetchDataAsset = async () => {
        const url = `${serverUrl}/check/getMasterCheck/${selectedAsset.codice}`;
        // alert(url);
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                const data = response.data;
                console.log(data);
                console.log(data.result.blocco1);
                setBlocchi(data.result.blocco1);
            } else if (response.status === 400) {
                const responseData = response.data;
                if (responseData && responseData.message) {
                    //   alert(responseData.message);
                } else {
                    alert("Errore: Master-checkList non trovata!");
                }
            } else {
                alert("Errore nella richiesta HTTP: " + response.status);
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data && error.response.data.message;
                if (errorMessage && errorMessage.includes("Master-checkList non trovata")) {
                    alert("Master checkList non trovata per :" + selectedAsset.codice);
                    return;
                } else {
                    alert("Errore nella richiesta HTTP: " + error.response.status);
                }
            } else {
                alert("Errore nella richiesta HTTP: " + error);
            }
            console.error('Errore nella richiesta HTTP:', error);
        }
    };

// legge la consigurazione per acquisire i valori aggiornati di priorità
// e determinare la scadenza
//=========================================================================
useEffect(() => {
    const leggiConf = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const response = await axios.get(`${serverUrl}/utils/read`);
        console.log(response.data);
        setAlta(response.data[0].alta);
        setMedia(response.data[0].media);
        setBassa(response.data[0].bassa);


      //  alert (' media vale:  '+media);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    leggiConf();
  }, []);


    // Ricavo lista asset da presentare nel combo di selezione
    const fetchDataAllAsset = async () => {
        try {
            // matricola="FA315"
            const serverUrl = process.env.REACT_APP_SERVER_URL;
            const response = await axios.get(`${serverUrl}/assets/`);
            console.log(response);
            if (response.status === 200) {
                const data = response.data;
                console.log(response.data);
                setListaAsset(data);
                //    console.log(listaAsset);
            } else {
                console.error('Errore nella richiesta HTTP');
            }
        } catch (error) {
            console.error('Errore nella richiesta HTTP:', error);
        }
    };

    //====================================================================

    useEffect(() => {
        fetchDataAllAsset();
    }, []);

    // Effetto per caricare i dati dell'asset selezionato quando selectedMatricola cambia
    useEffect(() => {
        const asset = listaAsset.find((item) => item.matricola === selectedMatricola);
        setSelectedAsset(asset);



    }, [selectedMatricola, listaAsset]);

    useEffect(() => {
        if (selectedAsset) {
            fetchDataAsset();
        }
    }, [selectedAsset]);


    const handleOnchangeData1 = (e) => {
        setData(e.target.value);
    }

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const onChangeProjectName = (e) => {
        setProjectName(e.target.value);
    }

    const onChangeAssignee = (e) => {
        setAssignee(e.target.value);
    }

    const onChangePriority = (e) => {
        setPriority(e.target.value);
    }

    const onChangeStatus = (e) => {
        setStatus(e.target.value);
    }

    const onChangeType = (e) => {
        setType(e.target.value);
    }

    const ricercaProgressivo = async () => {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const url = `${serverUrl}/tickets/getLastTicketFlag/${selectedMatricola}`;
        console.log(url);
        // Vado a cercare il valore di progressivo x segnalazione
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                // Analizza la risposta JSON
                const data = response.data;
                console.log(data.nextProgressivoFlag);
                setProgressivo(data.nextProgressivoFlag);
                return data.nextProgressivoFlag;
            } else {
                throw new Error('Errore durante la richiesta HTTP');
            }
        } catch (error) {
            console.error('Errore durante la richiesta HTTP:', error);
            // Gestisci l'errore in qualche modo, ad esempio visualizzando un messaggio all'utente
        }
    }



    const settaProgressivo = async () => {
        try {
            const progressivoValue = await ricercaProgressivo();
            console.log('Valore restituito da ricercaProgressivo():', progressivoValue);
            setProgressivo(progressivoValue);
            // Il resto del tuo codice...
        } catch (error) {
            console.error('Errore durante la ricerca del progressivo:', error);
            // Gestisci l'errore in qualche modo, ad esempio visualizzando un messaggio all'utente
        }
    }
    const onSubmit = async (e) => {

// imposta la data di scadenza
var newData = new Date(); // data corrente
var scadenza = new Date(newData); // clona la data corrente
// alert (`Alta:  $alta`+ alta);
// alert (`media:  $media`+media);
// alert (`bassa:  $bassa`+bassa);

// Aggiungi il numero di giorni in base alla priorità
if (priority === "Alta") scadenza.setDate(scadenza.getDate() + alta);
if (priority === "Media") scadenza.setDate(scadenza.getDate() + media);
if (priority === "Bassa") scadenza.setDate(scadenza.getDate() + bassa);
// alert (scadenza);

var scadenzaISO = scadenza.toISOString(); // Converti la data di scadenza in formato ISO

// alert(scadenzaISO); // Mostra la data di scadenza in formato ISO

// return; // da eliminare solo per il debug

        e.preventDefault();

        settaProgressivo();
        console.log(progressivo);

        // Salvo l' immagine sul server (se esiste e setto lo stato di image con il valore di ritorno dal server)
        handleUploadImage();
        const idUnico=uuidv4();
      //  alert (idUnico);
        const requestData = {
            "idTicket":uuidv4(),
            "codice": selectedAsset.codice,
            "data": newData.toISOString(),
            "scade": scadenzaISO,
            "targa": selectedAsset.matricola,
            "progressivo": progressivo,
            "title": title,
            "description": description,
            "projectName": projectName,
            "assignee": "A",
            "proprietario": userInfo.nome,
            "priority": priority,
            "status": status,
            "type": type,
            "image": image ? image.name : '',
            "foto": "",
        };
      //  console.log(requestData);
        const jsonData = JSON.stringify(requestData);
        console.log(jsonData);
      //  alert(jsonData);
      //======================================================================

        axios.post(`${serverUrl}/tickets/create`, jsonData, {
            headers: { "Content-Type": "application/json" },

        })
            .then((res) => console.log(res.data));

        // =========================================================================

        alert("Ho creato un nuovo ticket");


        //       clear form
        // this.setState({
        //   title: "",
        //   description: "",
        //   priority: "",
        //   status: "",
        //   type: "",
        //   image: "",
    }


    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);

    }


    const removeImage = () => {
        setImage('');
    }

    const handleMatricolaChange = (e) => {
        setSelectedMatricola(e.target.value);
        const asset = listaAsset.find((item) => item.matricola === e.target.value);
        setSelectedAsset(asset);
    };

    //======================================================================================

    const setDatiTicket = () => {
        if (selectedAsset) {
            setTarga(selectedAsset.matricola);
            setCodice(selectedAsset.codice);
            alert(selectedAsset.matricola + '--> ' + selectedAsset.codice);
        }
    }
    //  Invio immagine a Multer e memorizzazione del nome: 
    const handleUploadImage = async () => {
        try {
            console.log('Inizio handleUpload');
            const formDataImage = new FormData();
            formDataImage.append('image', image);
            console.log(Object.fromEntries(formDataImage.entries()))

            // Invio dell'immagine al server
            const serverUrl = process.env.REACT_APP_SERVER_URL;
            fetch(`${serverUrl}/tickets/uploadImage`, {
                method: 'POST',
                body: formDataImage,
            })
                .then(response => response.json())
                .then(data => {
                    //  console.log('Ho caricato:', data.imageFile);
                  //  alert('ho caricato: ' + image);
                 
                })
                .catch(error => {
                    console.error('Errore durante il caricamento dell\'immagine:', error);
                });

        } catch (error) {
            console.error('Errore durante il caricamento dell\'immagine:', error);
        }
    };


    //==========================================================================================

     return (
        <>
            <Box m="1.5rem 2.5rem">
                <h1 style={{ marginBottom: '20px' }}>Apertura ticket</h1>
                <div>
                    <h5>Seleziona una matricola:</h5>
                    <FormControl style={{ marginRight: '10px', width: '300px', marginBottom: '8px' }}>
                        <InputLabel id="matricola-label">Matricola</InputLabel>
                        <Select
                            labelId="matricola-label"
                            value={selectedMatricola}
                            onChange={handleMatricolaChange}
                            style={{ padding: '5px', borderRadius: '5px' }}
                            fullWidth
                        >
                            <MenuItem value="">Seleziona una matricola</MenuItem>
                            {listaAsset.map((asset) => (
                                <MenuItem key={asset._id} value={asset.matricola}>
                                    {asset.matricola}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    {selectedAsset && (
                        <div>
                            <h3>Dati dell'asset selezionato:</h3>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ marginRight: '20px' }}>
                                    <p>Tipo: {selectedAsset.codice}</p>
                                    <p>Denominazione: {selectedAsset.nome}</p>
                                    <p>Matricola: {selectedAsset.matricola}</p>
                                </div>
                                <div>
                                    <p>Acquisto: {selectedAsset.data_acquisto}</p>
                                    <p>Immatricolazione: {selectedAsset.data_immatricolazione}</p>
                                    <p>Scadenza assicurazione: {selectedAsset.data_scadenza_assicurazione}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2} marginTop={4}>
                        <Grid item xs={4}>
                            <TextField
                                label="Mezzo:"
                                fullWidth
                                value={`${selectedAsset?.codice}`}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Matricola"
                                fullWidth
                                value={`${selectedAsset?.matricola}`}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Data"
                                type="text"
                                name="data"
                                id="data"
                                onChange={handleOnchangeData1}
                                value={data.toLocaleString()}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="select-control">Seleziona un controllo</InputLabel>
                                <Select
                                    labelId="select-control"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                >
                                    <MenuItem value="">Seleziona un controllo</MenuItem>
                                    {blocchi && blocchi.map((item, index) => (
                                        <MenuItem key={index} value={item.Controllo}>
                                            {item.Controllo}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                label="Descrizione problema"
                                fullWidth
                                value={description}
                                onChange={onChangeDescription}
                                InputLabelProps={{ shrink: true }}
                            />
                       </Grid> 
         <Grid item xs={4}>             
        <InputLabel id="prior_label">Priorità</InputLabel>
        <Select
          labelId="prior_label"
          label="Priorità"
          name="priority"
          value={priority}
          onChange={onChangePriority}
          fullWidth
        >
          <MenuItem value={"Alta"}>Alta</MenuItem>
          <MenuItem value={"Media"}>Media</MenuItem>
          <MenuItem value={"Bassa"}>Bassa</MenuItem>
        </Select>
        </Grid>
        <Grid item xs={6}>
   
</Grid>

                     
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">Salva dati Ticket</Button>
                        </Grid>
                    </Grid>
                </form>

                {/* Secondo form per la selezione dell'immagine */}
                <form>
                    <Grid container spacing={2} marginTop={4}>
                        <Grid item xs={12}>
                            <label className="formLabel">Seleziona immagine o file pdf da allegare</label>
                            <input type="file" onChange={handleImageChange} />
                        </Grid>
                        {console.log(image)}
                        {image && (
                            <Grid item xs={12}>
                                <div>
                                    <img src={URL.createObjectURL(image)}
                                        alt="Caricamento terminato" width="200" height="150" />
                                    <Button variant="contained"
                                        onClick={removeImage}>Rimuovi immagine
                                    </Button>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Box>
        </>
    );
}

export default CreateTicket;
