import React, { Component } from 'react';
import TicketList2 from "./TicketList2";
import ChartPriorita from './charts/chart_priorita';
import ChartStatus from './charts/chart_status';
import ChartTipo from './charts/chart_tipo';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';



export default class Dashboard extends Component {
    render() {
        return( <Box m="1.5rem 2.5rem">
                 <table className="table table-bordered">
                    <thead className="thead-light">
                    <tr>
                       <th><h3>Conteggio aperti/scaduti </h3></th>
                        <th><h3>Stato tickets</h3></th>
                        <th><h3>Priorità tickets aperti</h3></th>
                       
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td><ChartTipo /></td>
                         <td><ChartStatus /></td>
                        <td><ChartPriorita /></td>
                     

                        </tr>
                    </tbody>
            </table>
            <TicketList2 />
            </Box>
        );
    }
}