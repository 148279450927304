import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SelectElement from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';


const InfoTicket = ({ open, onClose, ticket }) => {


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Dettaglio  ticket</DialogTitle>
      <DialogContent>
        <TextField
          readonly
          margin="dense"
          label="Data"
          name="Data"
          value={ticket.data}
         
          fullWidth
        />
        <TextField
          readonly
          margin="dense"
          label="Proprietario"
          name="proprietario"
          value={ticket.proprietario}
         
          fullWidth
        />
        <TextField
          margin="dense"
          label="Elemento controllato"
          name="title"
          value={ticket.title}
          
          fullWidth
          readonly
        />
        <TextField
          margin="dense"
          label="Descrizione problema"
          name="description"
          value={ticket.description}
          
          fullWidth
        />
        <InputLabel id="status-label">Stato</InputLabel>
        <TextField
          margin="dense"
          label="Descrizione problema"
          name="description"
          value={ticket.status}
          
          fullWidth
        />
        <InputLabel id="status-label">Priorità</InputLabel>
        <TextField
          margin="dense"
          label="Descrizione problema"
          name="description"
          value={ticket.priority}
          
          fullWidth
        />
     
     
        <Grid item xs={12} sm={4}>
          {ticket.foto !== "" && (
            <img
              src={`data:image/jpeg;base64,${ticket.foto}`}
              alt="Immagine"
              width={300}
              height={300}
              style={{ objectFit: 'cover' }}
            />

          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color="primary"
          onClick={onClose}>Chiudi
        </Button>
      
      </DialogActions>
    </Dialog>
  );
}

export default InfoTicket;
