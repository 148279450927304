import React, { useState, useEffect } from 'react';
import axios from 'axios';

import FiltroData from './FiltroData';
import FiltroCodice from './FiltraCodice';
import FiltroDataAsset from './FiltroDataAsset';
import Table from './Table';
import Filtro2Scadenze from './filtro2Scadenze';


const TicketList2 = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [initialTickets, setInitialTickets] = useState([]);
  const [ticketsChiusi, setTicketsChiusi] = useState([]);
  const [mostraCancellati, setMostraCancellati]=useState(true);

 // legge la consigurazione per decidere se mostrare o meno i tickets cancellati
  useEffect(() => {
    const leggiConf = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const response = await axios.get(`${serverUrl}/utils/read`);
        console.log(response.data);
        setMostraCancellati(response.data[0].mostraCancellati);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    leggiConf();
  }, []);




  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const url = `${serverUrl}/tickets`;
    console.log(url);
   // alert (url);
    axios.get(url)
      //   axios.get(`http://127.0.0.1:3500/tickets/`)
      .then(res => {
        console.log(res.data);
        setTickets(res.data);
        console.log(res.data);
        console.log(tickets);
        setFilteredTickets(res.data);
        setInitialTickets(res.data);

        console.log(filteredTickets);


      })
      .catch(error => console.log(error));


  }
    , []);



  const ricavaTicketChiusi = () => {
    // filtro per ricavare la lista dei ticket chiusi:
    const RisoltoTickets = filteredTickets.filter(ticket => ticket.status === 'Risolto');
    setTicketsChiusi(RisoltoTickets);
    //  getOpenList();

  }



  const handleFilterChange = (filteredTickets) => {
    setFilteredTickets(filteredTickets);
  }

  const handleResetFilter = () => {
    setFilteredTickets(initialTickets);
  }

  // Esegui solo quando filteredTickets cambia
  useEffect(() => {
    ricavaTicketChiusi();
  }, [filteredTickets]);

  let tic2 =[];// Ottieni l'array di ticket aperti
  if (mostraCancellati) {
     tic2 = filteredTickets.filter(ticket=>ticket.status !== 'Risolto');

  }  
  else
  {
     tic2 =
     filteredTickets.filter(ticket => (ticket.status !== 'Risolto') 
     && (ticket.status !== 'cancellato')
     
     );
  }

  // Stili x Filtri
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
  };

  const itemStyle = {
    border: '2px solid white',
    padding: '8px',
  };

  return (

    <div style={{ textAlign: 'center' }}>
      <br></br>
      <h3 className="MuiTypography-root MuiTypography-h3">Tickets aperti</h3>
      <div className="MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded filter-container"
      style={{marginBottom: '10px'}}>
      <div style={containerStyle}>
        <div style={itemStyle}>  <FiltroData
          className="MuiFormControl-root"
          onFilterChange={handleFilterChange}
          onResetFilter={handleResetFilter}
        />    </div>
        <div style={itemStyle}>
        <Filtro2Scadenze
          className="MuiFormControl-root"
          onFilterChange={handleFilterChange}
          onResetFilter={handleResetFilter}
        /> </div>
        
     
        <div style={itemStyle}>   <FiltroCodice
          className="MuiFormControl-root"
          onFilterChange={handleFilterChange}
          onResetFilter={handleResetFilter}
        /></div>
        <div style={itemStyle}>  <FiltroDataAsset
          className="MuiFormControl-root"
          onFilterChange={handleFilterChange}
          onResetFilter={handleResetFilter}
        /></div>


      </div>
      </div>
      {/* <Table  data={filteredTickets} rowsPerPage={4} /> */}
      <Table data={tic2} rowsPerPage={4} />

      <br></br>
      <h3>Tickets chiusi</h3>
      <Table data={ticketsChiusi} rowsPerPage={4} />
    </div>
  );
}

export default TicketList2