import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Typography, TextField, Grid} from "@mui/material";

function MostraStorico2() {
 
  const{id}=useParams();
  const  idTicket=id;
  // console.log(idTicket);
  
  // console.log(idTicket);
  const [ticket, setTicket] = useState();
  // const url = `http://127.0.0.1:3500/tickets/${id1}`;
 // console.log(url);
  //================================================================
  useEffect(() => {
    const serverUrl =process.env.REACT_APP_SERVER_URL;
    const url=`${serverUrl}/tickets/${id}`;
  // alert(url);
    axios       
      .get(url)
      .then((response) => {
        console.log(response.data);
        // console.log(data.storia);
        setTicket(response.data ? response.data :{});
        console.log(ticket);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  }, []);

  // Sezione modifica visualizzazione formato data: 

  const formattaData=(dataIn)=>{
   // const dateString = "2024-03-15T11:11:22.050Z";
    const date = new Date(dataIn);
    
    const formattedDate = date.toLocaleDateString(); // Ottiene la data formattata in base al fuso orario locale
    const formattedTime = date.toLocaleTimeString(); // Ottiene l'ora formattata in base al fuso orario locale
    
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;
    return formattedDateTime;

  }

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <div className="container">
        <Typography variant="h3">Dettagli del Ticket</Typography><br/>
        <form>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                label="Data"
                value={ticket ? formattaData(ticket.data) : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Asset"
                value={ticket ? ticket.codice : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="MAtricola"
                value={ticket ? ticket.targa : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Proprietario"
                value={ticket ? ticket.proprietario : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Controllo"
                value={ticket ? ticket.title : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Descrizione problema"
                value={ticket ? ticket.description : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
                style={{width: '350px'}}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Stato"
                value={ticket ? ticket.status : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Tipo"
                value={ticket ? ticket.type : ""}
                variant="standard"
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </form>
     

          <br />
          <h3>Storico Ticket</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Descrizione</TableCell>
                <TableCell>Stato</TableCell>
                <TableCell>Proprietario</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticket && ticket.storia.length > 0 ? (
                ticket.storia.map((ele2, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#AA5705' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#1A41CF',
                      },
                      '& > *': {
                        fontSize: '1.1rem', // Modifica la dimensione del carattere
                      },
                    }}
                  >
                    <TableCell>{formattaData(ele2.data)}</TableCell>
                    <TableCell>{ele2.descrizione}</TableCell>
                    <TableCell>{ele2.status}</TableCell>
                    <TableCell>{ele2.proprietario}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow key="empty">
                  <TableCell colSpan={4}>
                    <h2>dati non presenti</h2>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Box>
    </>
  );
              }
export default MostraStorico2;
