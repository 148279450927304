import React from "react";
import { createBrowserRouter } from "react-router-dom";

import PublicLayout from "layout/publicLayout";
import PublicHomePage from "pages/public/publicHomePage";

import AuthPage from "pages/auth/authPage";

import DashLayout from "layout/dashLayout";

import Dashboard from "components/Asite/components/dashboard.component";
import EditTicket2 from "components/Asite/components/editTicket2";
import MostraStorico2 from "components/Asite/components/MostraStorico2";
//import GetCheck from "components/Asite/components/getLastCheckList";
import GetCheck2 from "components/Asite/components/getCheckList2";
import CreateTicket from "components/Asite/components/create-ticket.component";
import CreaNewSchema from "components/Asite/components/creaNewSchema";
import CreateUser from "components/Asite/components/create-user.component";
import AssetsManager from "components/Asite/components/assetManager";
// import CreateUserTeam from "components/Asite/components/creaUserTeam";
import UsersManager from "components/Asite/components/usersManager";
import ConfigForm from "components/Asite/components/configurazione";

import UserTeams from "pages/dash/team/userTeam";


// import ErrorPage from "../pages/utility/ErrorPage";
// import NotFound from "../pages/utility/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <PublicHomePage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
      },
    ],
  },

  {
    path: "/edit/:id",
    element: <DashLayout />,

    children: [
      {
        index: true,
         element: <EditTicket2 tipo={1} />
      },
    ]
  },

  {
    path: "/leggi/:id",
    element: <DashLayout />,

    children: [
      {
        index: true,
         element: <EditTicket2 tipo={2} />
      },
    ]
  },

  {
    path: "/storia/:id",
    element: <DashLayout />,

    children: [
      {
        index: true,
         element: <MostraStorico2 />
      },
    ]
  },


  {
    path: "/dash",
    element: <DashLayout />,

    children: [
      {
        index: true,
       // element: <DashHomePage />,
        element: <Dashboard />
      },

      {
        path: "assetsManager",
        element: <AssetsManager />,
      },

      {
        path: "getCheck",
        element: <GetCheck2 />,
      },
      {
        path: "creaSchema",
        element: <CreaNewSchema/>,
      },
      {
        path: "creaTicket",
        element: <CreateTicket />,
      },
      {
        path: "creaUtente",
        element: <CreateUser />,
      },
      {
        path: "usersManager",
        element:<UsersManager/>
      
      },

      {
        path: "configura",
        element: <ConfigForm />,
      }

      // {
      //   path: "logbraccialetti",
      //   element: <LogBraccialetti />,
      // },
      // {
      //   path: "lognotifiche",
      //   element: <LogAlarm />,
      // },
      // {
      //   path: "lavoratori",
      //   element: <Workers />,
      // },
      // {
      //   path: "commesse",
      //   element: <Jobs />,
      // },
      // {
      //   path: "opcenters",
      //   element: <OpCenters />,
      // },
      // {
      //   path: "braccialetti",
      //   element: <Braccialetti />,
      // },
      // {
      //   path: "veicoli",
      //   element: <Veicoli />,
      // },
      // {
      //   path: "gateways",
      //   element: <Gateways />,
      // },
      // {
      //   path: "scheduler",
      //   element: <Scheduler />,
      // },
     
    ],
  },
  {
    path: "*",
    // element: <NotFound />,
  },
]);

export default router;
