import { lightBlue } from '@mui/material/colors';
import TableCellWithCircles from './graphTicketOpen';
import React from 'react';
import { Button } from '@mui/material';


const cellStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'center', // Centra il testo nelle celle
};


const CheckListTable = ({ checkList, ticketsAperti, index, handleRowSelect }) => {
  return ( <tbody>
        <tr style={{backgroundColor: 'cyan', color: 'blue'}}>
         <td style={{ border: '1px solid black', padding: '8px' , textAlign: "center"}}>{checkList.p1.codice}</td>
          <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>{new Date(checkList.p1.data).toLocaleString()}</td>
          <td style={{ border: '1px solid black', padding: '8px' ,  textAlign: "center"}}>{checkList.p1.matricola}</td>
         
          <td style={{ border: '1px solid black', padding: '8px' , textAlign: "center"}}>{checkList.p1.operatore}</td>
          <td style={{ border: '1px solid black', padding: '8px' , textAlign: "center"}}>{checkList.p1.progressivo}</td>
          <td style={{ border: '1px solid black', padding: '8px' }}><TableCellWithCircles ticketsAperti={ticketsAperti}></TableCellWithCircles></td>
         
          <td align="center"> {/* Aggiungi il td per il pulsante "Seleziona" */}
      <Button variant="contained" color='secondary'
  sx={{
    borderRadius: 50
  }} onClick={() => handleRowSelect(index)}>Seleziona</Button>
    </td>
        </tr>
      </tbody>
  
  );
};

export default CheckListTable;
