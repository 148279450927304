import { useState , useEffect} from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const FiltroData = ( {onFilterChange ,  onResetFilter }) => {

const [data1, setData1]=useState(''); // inizio ricerca
const [data2, setData2]=useState(''); // fine
const [tickets, setTickets]=useState([]);


const handleResetFiltro=() =>{
  onResetFilter()
 }

const handleOnchangeData1=(e) =>
{
  setData1(e.target.value);
}

const handleOnchangeData2=(e) =>
{
  setData2(e.target.value);
}


useEffect(() => {
    // Questo effetto collaterale si attiva ogni volta che 'tickets' viene modificato
    console.log(tickets);
}, [tickets]); // Dipendenza: si attiva solo quando 'tickets' cambia

const handleOnClick = () => {
  console.log(data1,  data2);
  if (data2 >= data1 ) {
    const serverUrl =process.env.REACT_APP_SERVER_URL;
    fetch(`${serverUrl}/tickets/dateRange/${data1}/${data2}`)
    .then(response => {
      if (response.status === 400) {
        alert('Non trovo dati nel DB corrispondenti alla ricerca!')
        throw new Error('No data found for the provided parameters');
      }
      return response.json();
    })
      .then(data => {
        // Aggiorna lo stato nel componente principale
        onFilterChange(data);
      })
      .catch(error => console.error('Error:', error));
  } else   alert('Data inizio successiva a quella finale, controllare ! ')

     
    
  }
  return (
    <>
      <div className="MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
        <div className="MuiFormControl-root">
          {/* Filtra per data */}
        
          <TextField
            label="Inizio"
            type="date"
            name="d1"
            id="d1"
            onChange={handleOnchangeData1}
            value={data1}
            InputLabelProps={{
              shrink: true,
            }}
            style ={{backgroundColor: '#0F668EE0', marginRight: '10px',}}
          />
          <TextField
            label="Fine"
            type="date"
            name="d2"
            id="d2"
            onChange={handleOnchangeData2}
            value={data2}
            InputLabelProps={{
              shrink: true,
            }}
            style ={{backgroundColor: '#0F668EE0'}}
          />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
            <Button
            className="riga"
            onClick={handleResetFiltro}
            size="small"
            variant="contained"
            color="primary"
            style={{marginRight: '10px' }}>
          
            Reset filtro
          </Button >
           <Button
          
            disabled={!data1 || !data2}
            onClick={handleOnClick}
            variant="contained"
            color="primary"
          >
            Ricerca per data creazione
          </Button></div>
         
        </div>
      </div>
    </>
  );
}
export default FiltroData