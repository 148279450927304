import { Box, Typography, Container, Grid } from "@mui/material";
import { SafetyCheck } from "@mui/icons-material";
import React from "react";
import AuthPage from "pages/auth/authPage";
import logoAsite from "../../../assets/logoasite.png";
import logoIota from "../../../assets/logoIota.jpeg"
import motori from "../../../assets/motori.jpg";
import logoArtes from "../../../assets/logo_artes.png";

function PublicHomePage() {
  return (
    <Box sx={{ pt: 4, pb: 4 }}>
      <Grid container spacing={2}>
        {/* Parte sinistra */}
        <Grid item xs={6}>
             {/* Testo */}
             <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              ASITE
            </Typography>
            <Typography variant="h5" align="center" paragraph>
              Sistema di gestione dei controlli funzionali degli assets aziendali
            </Typography>
          </Container>
          {/* Immagine */}
          <Container maxWidth="sm">
            <img src={motori} width={600} height={400} alt="Motori" />
          </Container>
          {/* Spazio verticale */}
          <Box mt={4} />
          {/* Loghi */}
          <Container maxWidth="sm">
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ paddingLeft: "110px" }}>
                <img src={logoAsite} width={180} height={70} alt="Logo Asite" />
              </Grid>
              <Grid item xs={6}>
                <img src={logoIota} width={180} height={70} alt="Logo Iota" />
              </Grid>
            </Grid>
          </Container>
            {/* Spazio verticale */}
          <Box mt={4} />
          <Typography variant="h5" align="center" paragraph>
              Finanziato dal Bando BIT INAIL - ARTES 4.0 N1 -2022
            </Typography>
            <Container maxWidth="sm" style={{ paddingLeft: "180px" }}>
            <img src={logoArtes} width={220} height={70} alt="logo Artes" />
          </Container>
        </Grid>
        {/* Parte destra */}
        <Grid item xs={6}>
          {/* Autenticazione */}
          <AuthPage />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PublicHomePage;

