import {
  HomeOutlined,
  AirportShuttle,
  Poll,
  Router,
  Engineering,
  PendingActions,
  Hub,
  Notifications,
  Watch,
  CalendarMonth,
  GroupAdd,
  GroupWorkIcon,
  Description,
  Summarize,
  Assignment,
  PrecisionManufacturingOutlined,

} from "@mui/icons-material";

import GroupsIcon from '@mui/icons-material/Groups';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useSelector } from 'react-redux';



export const dashSideBarNavItems =

[
  {
    text: "Dashboard",
    url: "/dash",
    icon: <HomeOutlined />,
  },
  {
    text: "Gestione assets",
    url: "/dash/assetsManager",
    icon:  <PrecisionManufacturingOutlined />
  },
  {
    text: "Gestione checklist",
    icon: null,
  },
  {
    text: "Consulta checklist",
    url: "/dash/getCheck",
    icon: <Summarize />,
  },
  {
    text: "Crea schema checklist",
    url: "/dash/creaSchema",
    icon: <Assignment />,
  },
  {
    text: "Ticketing",
    icon: null,
  },
  // {
  //   text: "Commesse",
  //   url: "commesse",
  //   icon: <PendingActions />,
  // },
  {
    text: "Apertura ticket",
    url: "/dash/creaTicket",
    icon: <Description />,
  },
  // {
  //   text: "Centri Operativi",
  //   url: "opcenters",   da vedere anche scheduler per interesse gestione calendario!
  //   icon: <Hub />,
  // },
  {
    text: "Gestione utenti",
    icon: null,
  },
  {
    text: "Nuovo utente",
    url: "/dash/creaUtente",
   // icon: <Engineering />,
   icon: <GroupAdd />
  },

  {
    text: "Gestione utenti",
    url: "/dash/usersManager",
   // icon: <Engineering />,
   icon: <GroupsIcon />
  },
  {
    text: "Configurazione",
    icon: null,
  },
  {
    text: "impostazioni",
    url: "/dash/configura",
   // icon: <Engineering />,
   icon: <SettingsApplicationsIcon/>
  },



  // {
  //   text: "Braccialetti",
  //   url: "braccialetti",
  //   icon: <Watch />,
  // },
  // {
  //   text: "Veicoli",
  //   url: "veicoli",
  //   icon: <AirportShuttle />,
  // },
  // {
  //   text: "Gateway",
  //   url: "gateways",
  //   icon: <Router />,
  // },
];



export default dashSideBarNavItems;
