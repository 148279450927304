import { apiSlice } from "state/redux/apiSlice.js";

// export const teamsApiSlice = apiSlice.injectEndpoints({
//   endpoints: (builder) => (
//     {
//     // getteams: builder.query({
//     //   query: () => `/usersteam/get`,
//     //   providesTags: ["Team"],
//     // }),
//     addTeam: builder.mutation({
//       query: (data) => ({
//         url: `/usersteam/addusersteam`,
//         method: "POST",
//         body: data,
//       }),
//       invalidatesTags: ["Team"],
//     }),
//     // deleteteams: builder.mutation({
//     //   query: (data) => ({
//     //     url: `/worker/delete`,
//     //     method: "DELETE",
//     //     body: data,
//     //   }),
//     //   invalidatesTags: ["Worker"],
//     // }),
//     // updateWorker: builder.mutation({
//     //   query: (data) => ({
//     //     url: `/worker/update`,
//     //     method: "PATCH",
//     //     body: data,
//     //   }),
//     //   invalidatesTags: ["Worker"],
//     // }),
//   }),
// });

// export const {

// useAddTeamMutation,
// //   useGetteamsQuery,
 
// //   useDeleteteamsMutation,
// //   useUpdateWorkerMutation,
// } = teamsApiSlice;
// teamsApiSlice.js

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://192.168.1.210:3500";

const getToken = () => {
  const name = "jwt=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    const token = getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const teamsApiSlice = createApi({
  reducerPath: 'teamsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    addUserTeam: builder.mutation({
      query: (newUserTeam) => ({
        url: '/usersteam/addusersteam',
        method: 'POST',
        body: newUserTeam,
      }),
    }),
  }),
});

export const { useAddUserTeamMutation } = teamsApiSlice;


