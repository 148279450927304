// @/src/components/Table/index.jsx
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import useTable from '../../Hooks/useTable';
import styles from "./TableFooter/TableFooter.module.css";
import TableFooter from "./TableFooter";

const Table = ({ data, rowsPerPage }) => {
  console.log(data);
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);





  const deleteTicket = async (id) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    try {
      const res = await axios.delete(`${serverUrl}/tickets/${id}`);
      const { message, refresh } = res.data;
      alert(message);
      // Rimuovi il ticket eliminato dagli elenchi di ticket
      const updatedTickets = tickets.filter(el => el._id !== id);
      setTickets(updatedTickets);
      // Aggiorna solo i ticket filtrati se il ticket eliminato era presente nell'elenco filtrato
      const updatedFilteredTickets = filteredTickets.filter(el => el._id !== id);
      setFilteredTickets(updatedFilteredTickets);
      // Ora che il ticket è stato eliminato, aggiorna la lista dei ticket aperti
      if (refresh) {
        window.location.reload(); // Refresh della pagina client
      }
    } catch (error) {
      console.log(error);
      // Gestione dell'errore, se necessario
    }
  };





  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
            <th className={styles.tableHeader}>Data scadenza</th>
            <th className={styles.tableHeader}>Data creazione</th>
            <th className={styles.tableHeader}>Controllo</th>
            <th className={styles.tableHeader}>Descrizione</th>
            <th className={styles.tableHeader}>Priorità</th>
            <th className={styles.tableHeader}>Stato</th>
            <th className={styles.tableHeader}>Asset</th>
            <th className={styles.tableHeader}>Matricola</th>
            <th className={styles.tableHeader}>Tipo</th>
            <th className={styles.tableHeader}>Proprietario</th>
            <th className={styles.tableHeader}>Azioni</th>
          </tr>
        </thead>
       <tbody>
  {slice.map((el) => (
   <tr 
      style={{
               backgroundColor: el.status === "Risolto" ? 'lightblue' :
               new Date(el.scade) > new Date() ? 'lightgreen' : 'lightcoral'
      }} 
   className={styles.tableRowItems} key={el._id}>
   <td className={styles.tableCell}>
   <td className={styles.tableCell}>
       {el.scade != null ? new Date(el.scade).toLocaleString() : ""}
   </td>

    </td>


  <td className={styles.tableCell}>{new Date(el.data).toLocaleString()}</td>
  <td className={styles.tableCell}>{el.title}</td>
  <td className={styles.tableCell}>{el.description}</td>
  <td className={styles.tableCell}>{el.priority}</td>
  <td className={styles.tableCell}>{el.status}</td>
  <td className={styles.tableCell}>{el.codice}</td>
  <td className={styles.tableCell}>{el.targa}</td>
  <td className={styles.tableCell}>{el.type}</td>
  <td className={styles.tableCell}>{el.proprietario}</td>

  <td>
    <Link to={{
      pathname: `/edit/${el._id}`,
      state: { ticketData: el } // Passa l'oggetto el come state
    }} className="badge badge-info">Edit</Link>
    <br></br>

    <a href="#" onClick={() => {
      if (window.confirm('Confermi la cancellazione di questo ticket'))
        deleteTicket(el._id)
    }}
      className="badge badge-danger">Cancella</a>
    <br></br>
{/* mostra il badge storico solo se la storia esiste !!!!! */}
    {el.storia.length > 0 && (
      <div>
        <Link
          to={{
            pathname: `/storia/${el._id}`,
            state: { ticketData: el }
          }}
          className="badge badge-info"
        >
          Storico
        </Link>
      </div>
    )}
  
  </td>

</tr> 
  ))}
</tbody>

      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page} />
    </>
  );
};

export default Table;