import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Grid, Box } from '@mui/material'; // Importa i componenti necessari da Material-UI
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const roles = ['superadmin', 'admin', 'operatore'];

const CreateUser = () => {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVerify, setPasswordVerify] = useState('');
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');

    useEffect(() => {
        setRole(roles[0]); // set default value for role
    }, []);

    const onChangeUserName = (e) => {
        setUserName(e.target.value);
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const onChangeRole = (e) => {
        setRole(e.target.value);
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const onChangePasswordVerify = (e) => {
        setPasswordVerify(e.target.value);
    }

    const onChangeNome = (e) => {
        setNome(e.target.value);
    }

    const onChangeCognome = (e) => {
        setCognome(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        const generateUniqueEmail = () => {
            return `default_${uuidv4()}@asite.it`;
          };

        const user = {
            userName: userName,
            email: email || generateUniqueEmail(),
            role: role,
            password: password,
            nome: nome,
            cognome: cognome

        }

        const serverUrl = process.env.REACT_APP_SERVER_URL;
        console.log(user);

        // axios.post(`${serverUrl}/Auth/register`, user)
        axios.post(`${serverUrl}/users/creaUtente`, user)
        .then(res => {
            console.log(res.data);
            toast.success(`Aggiunto ${userName} agli utenti`, {
                position: "top-center",
                autoClose: 1000,
            });
        })
            .catch(err => console.log(err));

        // clear form
        setUserName('');
        setEmail('');
        setRole('');
        setPassword('');
        setNome('');
        setCognome('')
    }


    return (<>
        <Box m="1.5rem 2.5rem">
            <h3>Creazione nuovo utente</h3>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel id="username">Nome utente</InputLabel>
                        <TextField
                            labelId="username"
                            fullWidth
                            value={userName}
                            onChange={onChangeUserName}
                        // InputLabelProps={{ shrink: true }} // Sposta l'indicazione sul bordo
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="email">Email (facoltativa) </InputLabel>
                        <TextField
                            labelId="email"
                            fullWidth
                            type="email"
                            value={email}
                            onChange={onChangeEmail}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="pw">Password</InputLabel>
                        <TextField
                            labelId="pw"
                            fullWidth
                            type="password"
                            value={password}
                            onChange={onChangePassword}
                        // Sposta l'indicazione sul bordo
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel id="cpw">Conferma password</InputLabel>
                        <TextField
                            labelId="cpw"
                            fullWidth
                            type="password"
                            value={passwordVerify}
                            onChange={onChangePasswordVerify}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="Nome">Nome</InputLabel>
                        <TextField
                            labelId="Nome"
                            fullWidth
                            value={nome}
                            onChange={onChangeNome}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="cognome">Cognome</InputLabel>
                        <TextField
                            labelId="cognome"
                            fullWidth
                            value={cognome}
                            onChange={onChangeCognome}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ position: "relative", width: "100%", marginTop: "16px" }}>
                            <InputLabel htmlFor="ruolo"
                             style={{ position: "absolute", 
                             top: "-10px", left: "0" }}>
                                Ruolo
                            </InputLabel>
                            <Select
                                value={role}
                                onChange={onChangeRole}
                                labelId="ruolo"
                                inputProps={{ id: "ruolo" }}
                                fullWidth
                                style={{ marginTop: "8px" }}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>


                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">Conferma creazione utente</Button>
                    </Grid>
                </Grid>
            </form>

        </Box>
    </>
    );

}

export default CreateUser;

