
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function OpenListTickets({ listaIdTicket }) {
  const [tickets, setTickets] = useState([]);

  function Ticket({ _id, data, title, description, priority, status, proprietario , foto}) {
    return (
      <div style={{
        width: '300px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '10px'
      }}>
        <p>Data: {new Date(data).toLocaleString()}</p>
        <p>Controllo: {title}</p>
        <p>Descrizione problema: {description}</p>
        <p>Priorità: {priority}</p>
        <p>Stato: {status}</p>
        <p>Proprietario: {proprietario}</p>
        {foto !== "" ? (
          <img
            src={`data:image/jpeg;base64,${foto}`}
            alt="Immagine"
            style={{ height: '200px', width: '200px', objectFit: 'cover' }}
          />
        ) : (
          <div>Nessuna immagine a corredo</div>
        )}
        <div>
          <Link to={"/edit/"+_id} className="badge badge-info">Edit ticket</Link>
        </div>


      </div>
    );
  }

  useEffect(() => {
    async function fetchTickets() {
      const fetchedTickets = await Promise.all(
        listaIdTicket.map(async (idTicket) => {
          const serverUrl = process.env.REACT_APP_SERVER_URL;
          const getUrl = `${serverUrl}/tickets/leggi/${idTicket}`;
          const response = await fetch(getUrl);
          const ticket = await response.json();
          return ticket;
        })
      );
      setTickets(fetchedTickets);
    }

    fetchTickets();
  }, [listaIdTicket]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <h2>Lista Tickets aperti</h2>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px' /* Spazio tra i ticket */
      }}>
        {tickets.map((ticket) => (
          <Ticket key={ticket.idTicket} {...ticket} />
        ))}
      </div>
    </div>
  );
}

export default OpenListTickets;
