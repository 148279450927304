import { useState , useEffect} from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { FormControl, Select, MenuItem, InputLabel} from '@mui/material';

const FiltroDataAsset = ({onFilterChange, onResetFilter }) => {

  const [data1, setData1]=useState(''); // inizio ricerca
  const [data2, setData2]=useState(''); // fine
  const [asset, setAsset]=useState('');
  const [tickets, setTickets]=useState([]);
 
const [listaAsset, setListaAsset] = useState([]);
const [matricola, setMatricola] = useState('');

const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);

//Ricavo lista asset da presentare nel combo di selezione
const fetchDataAllAsset = async () => {
  try {
   // matricola="FA315"
   const serverUrl =process.env.REACT_APP_SERVER_URL;
    const response = await axios.get(`${serverUrl}/assets/`);
    if (response.status === 200) {
      const data = response.data;
      console.log(response.data);
      setListaAsset(data);
  //    console.log(listaAsset);
        } else {
      console.error('Errore nella richiesta HTTP');
    }
  } catch (error) {
    console.error('Errore nella richiesta HTTP:', error);
  }
};



useEffect(() => {
  fetchDataAllAsset();
}, []);

useEffect(() => {
  if (asset !== '') {
    // Qui inserisci la logica per registrare il valore su db
    console.log('Asset selezionato:', asset);
  }
}, [asset]); // 

  const handleResetFiltro=() =>{
    onResetFilter();
    
    console.log(asset);
   }

  const handleOnchangeAsset=(e) =>
  {
    setAsset(e.target.value);
  }
  
  const handleOnchangeData1=(e) =>
  {
    setData1(e.target.value);
  }
  
  const handleOnchangeData2=(e) =>
  {
    setData2(e.target.value);
  }
  
  
  useEffect(() => {
      // Questo effetto collaterale si attiva ogni volta che 'tickets' viene modificato
      console.log(tickets);
  }, [tickets]); // Dipendenza: si attiva solo quando 'tickets' cambia
  
  const handleOnClick = () => {
    console.log(data1,  data2);
    if (data1>data2)
    {
      // i 3 campi non sono compilati: 
      alert('Data inizio successiva a quella finale, controllare ! '+ data1 + ' '+data2+ ' '+ matricola)
    }
    
      const serverUrl =process.env.REACT_APP_SERVER_URL;
      fetch(`${serverUrl}/tickets/byCodeAndDate/${matricola}/${data1}/${data2}`)
      .then(response => {
        if (response.status === 400) {
          alert('Nessun dato corrisponde ai parametri di ricerca impostati!')
          throw new Error('No data found for the provided parameters');
        }
        return response.json();
      })
        .then(data => {
          // Aggiorna lo stato nel componente principale
          onFilterChange(data);
        })
        .catch(error => console.error('Error:', error));
    
    }
//=========================================================================
const handleMatricolaChange = (event) => {
  setMatricola(event.target.value);
};

//=========================================================================




    return (
      <div className="MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      {/* Filtra per data e tipo asset */}
      <TextField
        label="Inizio"
        type="date"
        name="d1"
        id="d1"
        onChange={handleOnchangeData1}
        value={data1}
        InputLabelProps={{
          shrink: true,
        }}
        style ={{backgroundColor: '#0F668EE0', marginRight: '10px',}} 

      />
      <TextField
        label="Fine"
        type="date"
        name="d2"
        id="d2"
        onChange={handleOnchangeData2}
        value={data2}
        InputLabelProps={{
          shrink: true,
        }}
        style ={{backgroundColor: '#0F668EE0', marginRight: '10px',}}
      />
      {/* Matricola */}
      <FormControl style={{ marginRight: '10px', width: '200px',  marginBottom: '8px'}}>
        <InputLabel id="matricola-label">Matricola</InputLabel>
        <Select
          labelId="matricola-label"
          value={asset.matricola}
          onChange={handleMatricolaChange}
          style={{ padding: '5px', borderRadius: '5px' }}
          fullWidth
        >
          <MenuItem value="">Seleziona una matricola</MenuItem>
          {listaAsset.map((asset) => (
            <MenuItem key={asset._id} value={asset.matricola}>
              {asset.matricola}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
    </div>
    
        {/* Pulsanti "Reset filtro" e "Avvia ricerca" sulla riga sottostante */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={handleResetFiltro}
            size="small"
            variant="contained"
            color="primary"
            style={{ marginRight: '10px' }}
          >
            Reset filtro
          </Button>
          
          <Button
            disabled={!data1 || !data2 || !matricola}
            onClick={handleOnClick}
            variant="contained"
            color="primary"
          >
            Avvia ricerca
          </Button>
        </div>
      </div>
    );
  }
  export default FiltroDataAsset