import { useState } from "react";

import {
  AppBar,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";

import {
  // Search,
  DarkModeOutlined, //switch light/dark mode
  LightModeOutlined, //switch light/dark mode
  ArrowDropDownOutlined,
  Notifications,
  Menu as MenuIcon,
} from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { setMode } from "state/redux/mode/modeSlice"; //switch light/dark mode
import { logout } from "state/redux/auth/authSlice";
import { useLogoutMutation } from "state/redux/auth/authApiSlice";

import { useNavigate, Link } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";

function DashNavBar({ userInfo, isSidebarOpen, setIsSidebarOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const theme = useTheme();

  const completeName = userInfo
    ? userInfo.nome
      ? userInfo.nome
      : userInfo.email
    : "";

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween gap="3rem">
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>

          {!isSidebarOpen && (
            <Typography
              variant="h4"
              fontWeight="bold"
              component={Link}
              sx={{
                textDecoration: "none",
                color: theme.palette.secondary.main,
              }}
              to="/"
            >
              Asite
             
            </Typography>
          )}

          {/* Casella di ricerca, opzionale */}
          {/* <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween> */}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* Bottone per switch light/dark mode */}
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>

          <IconButton>
            <Notifications sx={{ fontSize: "25px" }} />
          </IconButton>

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              {/* <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              /> */}
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {completeName}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {userInfo?.role}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={logoutHandler}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
}

export default DashNavBar;
