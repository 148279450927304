import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, TextField, Button, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import FormStoria from './formStoria';

const { REACT_APP_TELEGRAM_BOT_TOKEN, REACT_APP_TELEGRAM_CHAT_ID, REACT_APP_SERVER_URL } = process.env;

  // impostazione alcuni stili 
  const styles = {
    ticketInfo: {
      border: '1px solid #ccc',
      padding: '10px',
      marginBottom: '20px',
    },
    ticketInfoItem: {
      margin: '5px 0',
      fontSize: '16px',
    },
  };
  // ==============================================

const EditTicket2 = ({tipo}) => {

  const{id}=useParams();
  console.log(id);
  const[itData, setItData]=useState();
  const[description, setDescription]=useState();

 

 

  const [serverUrl]=useState(process.env.REACT_APP_SERVER_URL);
  console.log(serverUrl);

  const [ticket, setTicket] = useState( {});

  const [email, setEmail] = useState('');
  const [autoSend, setAutoSend] = useState('');


  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //=======================================================

  // Sezione modifica visualizzazione formato data: 
 //===========================================================
  const formattaData=(dataIn)=>{
    // const dateString = "2024-03-15T11:11:22.050Z";
     const date = new Date(dataIn);
     
     const formattedDate = date.toLocaleDateString(); // Ottiene la data formattata in base al fuso orario locale
     const formattedTime = date.toLocaleTimeString(); // Ottiene l'ora formattata in base al fuso orario locale
     
     const formattedDateTime = `${formattedDate}, ${formattedTime}`;
     return formattedDateTime;
 
   }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (tipo === 1) {
          response = await axios.get(`${serverUrl}/tickets/${id}`);
        } else if (tipo === 2) {
          response = await axios.get(`${serverUrl}/tickets/leggi/${id}`);
        }
        setTicket(response.data);
        console.log(response.data);
        console.log(ticket);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [tipo, serverUrl, id]);
  
  useEffect(() => {
    const leggiConf = async () => {
      try {
        const response = await axios.get(`${serverUrl}/utils/read`);
        setEmail(response.data[0].email);
        setAutoSend(response.data[0].autoSend);
        console.log(response.data[0].email);
        console.log(response.data[0].autoSend);
      } catch (error) {
        console.error(error);
      }
    };
  
    leggiConf();
  }, [serverUrl]);
  

//===================================================
 

  // Gestisce il cambiamento dell'input email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

// effetto per gestire l'invio automatico della mail se autoSend è true: 
// funzione disattivata, perchè attiva in app Asite
// useEffect(() => {
//   if (autoSend) {
//     // Invia automaticamente l'email
//     inviaEmail();
//   }
// }, [autoSend]);
  
  //==============================================================================
 // Gestione invio mail
const inviaEmail = () => {
  const destinatario1 = email;
  let oggetto1 = '';
  let testo2 = '';
  let testo3 = '';

  if (ticket.status === 'open') oggetto1 = 'Apertura nuovo ticket';
  if (ticket.status === 'Risolto') oggetto1 = 'Chiusura ticket';
  if (ticket.status === 'In Progress') oggetto1 = 'Aggiornamento stato ticket';

  const testo1 = `Progetto Asite- Informazioni sul ticket
Tipo: ${ticket.type}, stato: ${ticket.status}.
Data apertura: ${ticket.data}
Data scadenza: ${ticket.scade}
Riferito ad asset: ${ticket.codice}
Matricola: ${ticket.targa}
Elemento controllato: ${ticket.title}
Problema riscontrato: ${ticket.description}
Operatore: ${ticket.proprietario}`;

  if (ticket.storia.length > 0) {
    testo2 = '\n\n\nStoria interventi\n';
    ticket.storia.forEach((ele) => {
      testo3 += `Data modifica: ${ele.data}
Operatore: ${ele.proprietario}
Descrizione intervento: ${ele.descrizione}\n\n`;
    });
  }

  let testoMail = testo1 + testo2 + testo3;
  console.log(destinatario1);
  console.log(oggetto1);
  console.log(testoMail);

  if (!destinatario1 || !oggetto1 || !testoMail) {
    alert('Assicurati di compilare tutti i campi necessari alla mail.');
    return;
  }

  axios.post(`${serverUrl}/tickets/invia-email`, {
    destinatario: destinatario1,
    oggetto: oggetto1,
    testo: testoMail,
  })
  .then(response => {
    console.log(response.data);
    console.log(response.status);
    if (response.status === 200) {
      alert('Mail inviata al destinatario');
    }
  })
  .catch(error => {
    console.error(error);
  });
};


  // test invio Telegram
  // const sendMessageToTelegram = async () => {
  //   const TelegramBotToken = REACT_APP_TELEGRAM_BOT_TOKEN;
  //   const apiUrl = `https://api.telegram.org/bot${TelegramBotToken}/sendMessage`;
  //  // const msg =  document.getElementById("description").value;
  //   const msg =  ticket.description;
  //   console.log(msg);

  //   try {
  //     const response = await axios.post(apiUrl, {
  //       chat_id: REACT_APP_TELEGRAM_CHAT_ID,
  //       text: msg
  //     });

  //     if (response.data.ok) {
  //       console.log('Messaggio inviato con successo a Telegram');
  //       alert ('Messaggio inviato al canale Telegram: Ticket-iotalab')
  //     } else {
  //       console.error('Errore nell\'invio del messaggio a Telegram');
  //     }
  //   } catch (error) {
  //     console.error('Si è verificato un errore:', error);
  //   }
  // };


   // ===========================================================
  // handleFocus to clean field description
  const handleFocus = () => {
    setDescription('');
  };

  const showAlert = () => {
    //const msg = document.getElementById("description").value;
    const msg =  ticket.description;
    //alert(`Contenuto del campo: ${msg} ==> RIATTIVARE INVIO A TELEGRAM !`);
  //  sendMessageToTelegram(); // Mostra un alert con il contenuto dell'input
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  // const [currentDateTime, setCurrentDateTime] = useState(new Date());
 console.log(ticket);


// if (ticket) formattedDate = format(ticket.data, 'yyyy-MM-dd ');
 return (
    <Container maxWidth="sm">
      <Typography variant="h3" gutterBottom>Dettaglio ticket</Typography>
   <div style={styles.ticketInfo}>
  <p style={styles.ticketInfoItem}>Id ticket: {ticket.idTicket}</p>
  <p style={styles.ticketInfoItem}>Data creazione: {formattaData(ticket.data)}</p>
  { ticket.scade !=null &&
    <p style={styles.ticketInfoItem}>Data scadenza: {formattaData(ticket.scade)}</p>
  }
  <p style={styles.ticketInfoItem}>Asset: {ticket.codice}</p>
  <p style={styles.ticketInfoItem}>Matricola: {ticket.targa}</p>
  <p style={styles.ticketInfoItem}>Proprietario: {ticket.proprietario}</p>
  <p style={styles.ticketInfoItem}>Elemento controllato: {ticket.title}</p>
  <p style={styles.ticketInfoItem}>Problema riscontrato: {ticket.description}</p>
  <p style={styles.ticketInfoItem}>Stato: {ticket.status}</p>
  <p style={styles.ticketInfoItem}>Priorità: {ticket.priority}</p>
  <p style={styles.ticketInfoItem}>Tipo: {ticket.type}</p>
</div>

      <Grid container spacing={2}>
        <Grid item xs={12} >
           
              <Grid container spacing={2} style={{ marginTop: '2px' }}>
            
              <Grid item xs={12}>
                <Button variant="contained"  color="primary"  fullWidth
                 onClick={handleOpenDialog}>Aggiorna ticket</Button>
                 <FormStoria open={openDialog} onClose={handleCloseDialog} ticket={ticket} />
               </Grid>

              <Grid container spacing={2} style={{ marginTop: '1px' }}>
              <Grid item xs={12} sm={8}>
              <TextField
                  label="Email destinatario"
                  fullWidth
                  // required
                  value={email}
                  onChange={handleEmailChange}
                  InputLabelProps={{ shrink: true }}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                 <Button type="submit"
                  variant="contained"
                  color="primary"  
                  fullWidth  onClick={inviaEmail}
                  disabled={autoSend}
                  >
                  Invia email
                 </Button>
                 </Grid>

              </Grid>
            
                 {/* <Grid item xs={12} >
                 <Button variant="outlined" color="secondary"  fullWidth onClick={showAlert}>
                  Invia messaggio Telegram
                </Button>
                 
              </Grid> */}
            </Grid>
         
        </Grid>
       
        <Grid item xs={12} sm={4}>
      {ticket.foto=="" && ticket.image && (
	   
        <img src={`${serverUrl}/uploads/${ticket.image}`}  alt="Allegato al ticket" width="200" height="200" />
		
      )}
    </Grid>
    <Grid item xs={12} sm={4}>
      {ticket.foto!="" && ticket.image!="" && (
	   
        <img
          src={`data:image/jpeg;base64,${ticket.foto}`}
          alt="Immagine"
          width={300}
          height={300}
          style={{ objectFit: 'cover' }}
        />
		
      )}
    </Grid>

<Grid item xs={12} sm={4}>
  {ticket.image && getFileExtension(ticket.image) === 'pdf' && (
    <div>
      <a href={`${serverUrl}/uploads/${ticket.image}`} target="_blank" rel="noopener noreferrer">
        Visualizza PDF allegato
      </a>
    </div>
  )}
</Grid>
</Grid>
    </Container>
  );
  
  
  
};
export default EditTicket2;
