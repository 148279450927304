import React, { useState, useEffect } from 'react';
import axios from 'axios';
 import {Box, Table, TableBody, TableCell, TableContainer, TableHead,
   TableRow, Paper, TextField, Button, Grid ,
  FormControl, InputLabel, Select, MenuItem  
  } from '@mui/material';
import { useTheme } from "@mui/material";
import ConfirmDialog from "components/ConfirmDialog";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";
import {useNavigate } from 'react-router-dom';



const AssetsManager = () => {
  const [assets, setAssets] = useState([]);
 const navigate = useNavigate();
  const [newAsset, setNewAsset] = useState({
    codice: '',
    nome: '',
    matricola: '',
    data_acquisto: '',
    data_immatricolazione: '',
    data_scadenza_assicurazione: '',
    stato:'disponibile'
  });
  const [editingAssetId, setEditingAssetId] = useState(null); // ID dell'asset in fase di modifica
  const [deletingAssetId, setDeletingAssetId] = useState(null); // ID asset da cancellare !!!
  const [blockingAssetId, setBlockingAssetId] = useState(null); // ID asset da bloccare !!!
  const serverUrl =process.env.REACT_APP_SERVER_URL;
  
  useEffect(() => {
    // Carica tutti gli asset all'avvio
    getAllAssets();
  }, []);

// gestione cambiamento di stato con formControl
const handleInputChange = (fieldName, value) => {
  setNewAsset({ ...newAsset, [fieldName]: value });
};
//===========================================================

  const getAllAssets = async () => {
    try {
      const response = await axios.get(`${serverUrl}/assets/`);
      setAssets(response.data);
    } catch (error) {
      console.error('Errore durante il recupero degli asset:', error);
    }
  };

  const saveAsset = async () => {
    try {
      if (editingAssetId) {
        // Se si sta modificando un asset, invia una richiesta PATCH
        await axios.patch(`${serverUrl}/assets/update/${editingAssetId}`, newAsset);
      } else {
        // Se non si sta modificando un asset, invia una richiesta POST
        await axios.post(`${serverUrl}/assets/save`, newAsset);
      }
      // Aggiorna la lista degli asset
      getAllAssets();
      // Resetta lo stato del form e dell'ID dell'asset in fase di modifica
      setNewAsset({
        codice: '',
        nome: '',
        matricola: '',
        data_acquisto: '',
        data_immatricolazione: '',
        data_scadenza_assicurazione: '',
        stato:''
      });
      setEditingAssetId(null);
    } catch (error) {
      console.error('Errore durante il salvataggio dell\'asset:', error);
    }
  };

  const editAsset = (asset) => {
    // Imposta i valori dell'asset da modificare nel form
    setNewAsset(asset);
    // Imposta l'ID dell'asset in fase di modifica
    setEditingAssetId(asset._id);
  };

  const deleteAsset = async (id) => {
  
    try {
      await axios.delete(`${serverUrl}/assets/delete/${id}`); 
      getAllAssets();
    } catch (error) {
      console.error('Errore durante la cancellazione dell\'asset:', error);
    }
  };


  
  const bloccaAsset = async (id) => {
    console.log('id asset da bloccare ==>'+id);
    try {
      await axios.patch(`${serverUrl}/assets/blocca/${id}`, {"stato": "bloccato"}); 
      getAllAssets();
      alert ('Asset bloccato come da richiesta ');
    } catch (error) {
      console.error('Errore durante il blocco dell\'asset:', error);
    }
  };

// Gestione cancellazione con dialogo di Alert
const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
const [openBlockDialog, setOpenBlockDialog] = useState(false);


  const handleOpenConfirmDialog = (assetId) => {
    setOpenConfirmDialog(true);
    setDeletingAssetId(assetId);  // imposta id asset da cancellare
  };

  const handleOpenBlockDialog = (assetId) => {
    setOpenBlockDialog(true);
    setBlockingAssetId(assetId);  // imposta id asset da cancellare
  };

  const handleCloseConfirmDialog = ({ type }) => {
    if (type === "yes") 
      alert ('Codice asset in cancellazione:'+deletingAssetId);
      deleteAsset(deletingAssetId);
    setOpenConfirmDialog(false);
  };

  const handleCloseBlockDialog = ({ type }) => {
    if (type === "yes") 
     alert ('Codice asset in blocco:'+blockingAssetId);
    bloccaAsset(blockingAssetId);
    setOpenBlockDialog(false);
  };

  const theme = useTheme();

  return (<Box m="1.5rem 2.5rem">
   
      <h1>Asset Manager</h1>
      <div>
      <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          deletingAssetId={deletingAssetId} 
        />
      <ConfirmDialog
          openConfirmDialog={openBlockDialog}
          handleCloseConfirmDialog={handleCloseBlockDialog}
          blockingAssetId={blockingAssetId}
        />
        <h2>Lista degli Asset</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style ={{backgroundColor: '#55C1F3E0'}}>
                <TableCell style={{fontSize: '0.9rem' }}>Tipologia</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Nome</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Matricola</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Data acquisto</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Data immatricolazione</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Scadenza assicurazione</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Stato</TableCell>
                <TableCell style={{fontSize: '0.9rem' }}>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {assets.map(asset => (
                <TableRow key={asset._id} style ={{backgroundColor: theme.palette.primary.main,}}>

{/* color: theme.palette.primary.main */}
                  <TableCell style={{ fontSize: '0.9rem' }}>{asset.codice}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }} >{asset.nome}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }} >{asset.matricola}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }} >{asset.data_acquisto}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{asset.data_immatricolazione}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{asset.data_scadenza_assicurazione}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{asset.stato}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => editAsset(asset)}>Modifica</Button>
                    <Button variant="contained" color="secondary"
                             onClick={() => handleOpenConfirmDialog(asset._id)}>Elimina</Button>
                       <Button variant="contained" color="error"
                             onClick={() => handleOpenBlockDialog(asset._id)}>Blocca !</Button>
                            <Button variant="contained" color="primary"
                            onClick= {()=>navigate("/dash/creaSchema")}
                      >
                              Crea checklist</Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div >
        <h2>{editingAssetId ? 'Modifica Asset' : 'Aggiungi Nuovo Asset'}</h2>
       
          <Grid item xs={8} sx={{backgroundColor:   theme.palette.primary.main}}>
          <Box sx={{ mt: 2 }}> {/* Aggiunge spaziatura sopra */}
  <TextField 
    label="Tipologia" 
    fullWidth 
    value={newAsset.codice} 
    onChange={(e) => setNewAsset({ ...newAsset, codice: e.target.value })} 
 
    sx={{ fontSize: '1.5rem' }} 
  />
</Box>
<Box sx={{ mt: 2 }}> {/* Aggiunge spaziatura sopra */}
  <TextField 
    label="Nome" 
    fullWidth 
    value={newAsset.nome} 
    onChange={(e) => setNewAsset({ ...newAsset, nome: e.target.value })} 
   
  />
</Box>
<Box sx={{ mt: 2 }}> {/* Aggiunge spaziatura sopra */}
  <TextField 
    label="Matricola" 
    fullWidth 
    value={newAsset.matricola} 
    onChange={(e) => setNewAsset({ ...newAsset, matricola: e.target.value })} 
   
  />
</Box>
<Grid container spacing={2}>
      <Grid item xs={3}>
      <Box sx={{ mt: 2 }}>
        <TextField 
          type="date" 
          label="Data acquisto" 
          fullWidth 
          value={newAsset.data_acquisto} 
          onChange={(e) => handleInputChange('data_acquisto', e.target.value)} 
          InputLabelProps={{ shrink: true }} 
        />
        </Box>
      </Grid>
      <Grid item xs={3}>
      <Box sx={{ mt: 2 }}>
        <TextField 
          type="date" 
          label="Data immatricolazione" 
          fullWidth 
          value={newAsset.data_immatricolazione} 
          onChange={(e) => handleInputChange('data_immatricolazione', e.target.value)} 
          InputLabelProps={{ shrink: true }} 
        />
        </Box>
      </Grid>
      <Grid item xs={3}>
      <Box sx={{ mt: 2 }}>
        <TextField 
          type="date" 
          label="Data scadenza assicurazione" 
          fullWidth 
          value={newAsset.data_scadenza_assicurazione} 
          onChange={(e) => handleInputChange('data_scadenza_assicurazione', e.target.value)} 
          InputLabelProps={{ shrink: true }} 
        />
        </Box>
      </Grid>
      <Grid item xs={3}>
      <Box sx={{ mt: 2 }}>
        {/* <TextField 
          label="Stato" 
          fullWidth 
          value={newAsset.stato} 
          onChange={(e) => handleInputChange('stato', e.target.value)} 
          InputLabelProps={{ shrink: true }} 
        /> */}
           <FormControl fullWidth>
      <InputLabel id="stato-label">Stato</InputLabel>
      <Select
        labelId="stato-label"
        value={newAsset.stato}
        onChange={(e) => handleInputChange('stato', e.target.value)}
        fullWidth
      >
        <MenuItem value="disponibile">Disponibile</MenuItem>
        <MenuItem value="bloccato">Bloccato</MenuItem>
        {/* Aggiungi altri valori dello stato come MenuItem se necessario */}
      </Select>
    </FormControl>
        </Box>
      </Grid>
     
    </Grid>




{/* Rimuovi la chiusura di </Grid> e sposta il bottone di salvataggio qui dentro */}
<Grid item xs={12}>
<Box sx={{ mt: 4 }}>
  <Button variant="contained" color="secondary"
  style={{width: '250px'}}
  onClick={saveAsset}>{editingAssetId ? 'Conferma le modifiche' : 'Salva nuovo asset'}</Button>
  </Box>
</Grid>

</Grid>

    </div>
  
    </Box>
     
  );
};
export default AssetsManager;
