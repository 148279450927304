import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button, Grid, Paper } from '@mui/material';
import axios from 'axios';
import { toast } from "react-toastify";

const ConfigForm = () => {
  const [email, setEmail] = useState('');
  const [autoSend, setAutoSend] = useState(false);
  const [autoTelegram, setAutoTelegram] = useState(false);
  const [mostraCancellati, setMostraCancellati] = useState(false);
  const [bloccoMezzo, setBloccoMezzo] = useState(false);
  const [alta, setAlta] = useState(1);
  const [media, setMedia] = useState(3);
  const [bassa, setBassa] = useState(5);
 

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAutoSendChange = (event) => {
    setAutoSend(event.target.checked);
  };

  // const handleAutoTelegramChange = (event) => {
  //   setAutoTelegram(event.target.checked);
  // };

  const handleMostraCancellati = (event) => {
    setMostraCancellati(event.target.checked);
   
  };

  const handleBloccoMezzo = (event) => {
    setBloccoMezzo(event.target.checked);
   
  };

  const handlePrioAlta= (event) => {
    const intValue = parseInt(event.target.value, 10);
    setAlta(intValue);
  };

  const handlePrioMedia= (event) => {
    const intValue = parseInt(event.target.value, 10);
    setMedia(intValue);
  };

  const handlePrioBassa= (event) => {
    const intValue = parseInt(event.target.value, 10);
    setBassa(intValue);
  };


  const handleSubmit = async () => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const res = await axios.patch(`${serverUrl}/utils/update`, {mostraCancellati, email, autoSend , autoTelegram, bloccoMezzo,alta,media,bassa});
      console.log(res.data);
      toast.success('Dati di configurazione memorizzati', {
        position: "top-center",
        autoClose: 1000,
      }); // log response from server
    } catch (error) {
        console.log(error?.data)
        toast.error(error?.data?.message || error.error, { position: "top-center" });
    }
  };


  // legge la consigurazione per decidere se mostrare o meno i tickets cancellati
  useEffect(() => {
    const leggiConf = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const response = await axios.get(`${serverUrl}/utils/read`);
        console.log(response.data);
        setMostraCancellati(response.data[0].mostraCancellati);
        setAutoSend(response.data[0].autoSend);
        setAutoTelegram(response.data[0].autoTelegram);
        setEmail(response.data[0].email);
        setBloccoMezzo(response.data[0].bloccoMezzo);
        setAlta(response.data[0].alta);
        setMedia(response.data[0].media);
        setBassa(response.data[0].bassa);
        
      } catch (error) {
        console.error(error);
      }
    };
  
    leggiConf();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '400px', backgroundColor: '#221683'}}>
      <Grid  container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Email (destinatario predefinito)"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={autoSend} onChange={handleAutoSendChange} />}
            label="Invio automatico mail"
            style={{ backgroundColor: autoSend ? 'lightCoral' : 'transparent' }} 
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={autoTelegram} onChange={handleAutoTelegramChange} />}
            label="Invio automatico messaggio Telegram"
            style={{ backgroundColor: autoTelegram ? 'lightCoral' : 'transparent' }} // Cambia il colore dello sfondo in base allo stato selezionato
          />
        </Grid> */}
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={mostraCancellati} onChange={handleMostraCancellati} />}
            label="Mostra tickets cancellati"
            style={{ backgroundColor: mostraCancellati ? 'lightCoral' : 'transparent' }} 
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={bloccoMezzo} onChange={handleBloccoMezzo} />}
            label="Blocca asset se priorità alta"
            style={{ backgroundColor: bloccoMezzo ? 'lightCoral' : 'transparent' }} 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Valore (in giorni) predefinito per priorità Alta"
            variant="outlined"
            fullWidth
            value={alta}
            onChange={handlePrioAlta}
           />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Valore (in giorni) predefinito per priorità Media"
            variant="outlined"
            fullWidth
            value={media}
            onChange={handlePrioMedia}
           />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Valore (in giorni) predefinito per priorità Bassa"
            variant="outlined"
            fullWidth
            value={bassa}
            onChange={handlePrioBassa}
           />
        </Grid>


        
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salva
          </Button>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
};
export default ConfigForm;
