import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  Select,
  MenuItem,
  InputLabel,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

import { useTheme } from "@mui/material";
import { green, red } from "@mui/material/colors";
import ConfirmDialog from "components/ConfirmDialog";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";

const CreaNewSchema = () => {
  const [listaAsset, setListaAsset] = useState([]);
  const [codice, setCodice] = useState(''); // Aggiunto lo stato per il campo 'codice'
  const [newCodice, setNewCodice] = useState('');
  const [campoVuoto, setCampoVuoto] = useState(false);
  const [blocchi, setBlocchi] = useState([]);
  const [controllo, setControllo] = useState('');
  const [tipologia, setTipologia] = useState('Sicurezza');
  const [priorita, setPriorita] = useState('Alta');

  const _tipologia = ['Sicurezza', 'Controlli funzionalità', 'Controlli visuali'];
  const _priorita = ['Alta', 'Media', 'Bassa'];
  const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rowData, setRowData] = useState({});
  const [editedData, setEditedData] = useState({});


  // gestione dialogo modale x modifica elemento esistente
  // Funzione per aprire il dialogo modale e impostare l'elemento in modifica
  const apriDialogoModifica = (index) => {

    setRowData(blocchi[index]); // Imposta i dati della riga selezionata
    setEditedData(blocchi[index]); // imposta i dati modificabili
    setIsDialogOpen(true);
  };

  // Funzione per chiudere il dialogo modale
  const chiudiDialogoModifica = () => {
    setIsDialogOpen(false);
    setEditedData({});
  };


  const handleClose = () => {
    setIsDialogOpen(false);
    setEditedData({}); // Resettare i dati modificabili
  };


  const handleSave = () => {
    // Salvare i dati modificati e chiudere il dialogo
    handleChange(blocchi.indexOf(rowData), editedData);
    handleClose();
  };

  // Funzione per gestire il cambiamento della Select
 // Definisci una funzione per gestire il cambio di tipologia
 const handleTipologiaChange = (event) => {
  const newTipologia = event.target.value;
  setTipologia(newTipologia);

  // Imposta la priorità in base alla tipologia
  let newPriorita = '';
  switch (newTipologia) {
    case 'Sicurezza':
      newPriorita = 'Alta';
      break;
    case 'Controlli funzionalità':
      newPriorita = 'Media';
      break;
    case 'Controlli visuali':
      newPriorita = 'Bassa';
      break;
    default:
      newPriorita = '';
      break;
  }
  setPriorita(newPriorita); // Aggiorna la priorità dopo aver impostato la tipologia
};



   // Funzione per gestire il cambiamento della Select
    const handlePrioritaChange = (event) => {
      setPriorita(event.target.value);
    //  handleEditChange({ target: { name: 'Priorità', value: event.target.value } });
      setEditedData(prevState => ({
        ...prevState,
        Priorità: event.target.value
      }));
    };


  // ==================================================================================================

  const fetchDataAllAsset = async () => {
    try {
      // codice='Autocarro2';

      const response = await axios.get(`${serverUrl}/check/getMasterCheckN`);
      if (response.status === 200) {
        const data = response.data;
     //   console.log(data);
        setListaAsset(data.codici);
     //   console.log(listaAsset);


      } else {
        console.error('Errore nella richiesta HTTP');
      }
    } catch (error) {
      console.error('Errore nella richiesta HTTP:', error);
    }
  };


  useEffect(() => {
    fetchDataAllAsset();
  }, []);


  // funzione aggiorna schema master


  const aggiornaMasterSchema = async () => {
    try {
      console.log(codice)
      console.log(blocchi);
   //   alert(codice);
      alert(blocchi);

      console.log(' SERVER URL ========> ' + serverUrl);
      await axios.put(`${serverUrl}/check/updateMasterSchema/${codice}`, {
        blocco1: blocchi
      });

      console.log('Lista salvata con successo');
      alert('Schema checkList aggiornato');
    } catch (error) {
      console.error('Errore nel salvataggio dello schema checkList', error.code);
    }
  };



  const convertJsonListToMap = () => {
    const result = {};
    blocchi.forEach(item => {
      const nomeControllo = item['Controllo'];
      result[nomeControllo] = 'nc';
    });
    return result;
  };

  const aggiungiElemento = async () => {
    const nuovoElemento = {
      Controllo: controllo,
      Stato: 'nc',
      Tipologia: tipologia,
      Priorità: priorita,
      Note: '',
      imgPath: '',
      img64: ''
    };
    setBlocchi([...blocchi, nuovoElemento]);
    setControllo('');
  };
  //=========================================================================================
  const eliminaElemento = async (index) => {
    try {
    
      // Effettua la richiesta di eliminazione dell'elemento al backend

      await axios.delete(`${serverUrl}/check/deleteElemento/${codice}/${index}`);

      // Copia la lista blocchi
      const updatedBlocchi = [...blocchi];
      // Rimuovi l'elemento dalla lista con l'indice specificato
      updatedBlocchi.splice(index, 1);
      // Aggiorna lo stato blocchi con la lista aggiornata
      setBlocchi(updatedBlocchi);
    } catch (error) {
      console.error('Errore nell\'eliminazione dell\'elemento:', error);
    }
  };

  const fetchDataAsset = async () => {
    try {
      // codice='Autocarro2';
      const response = await axios.get(`${serverUrl}/check/getMasterCheck/${codice}`);
      if (response.status === 200) {
        const data = response.data;
        setBlocchi(data.result.blocco1);
        console.log(data.result.blocco1);
      } else {
        console.error('Errore nella richiesta HTTP');
      }
    } catch (error) {
      console.error('Errore nella richiesta HTTP:', error);
    }
  };

  const salvaLista = async () => {
    try {
      console.log(blocchi);
      console.log(newCodice);
      console.log(' SERVER URL ========> ' + serverUrl);
      await axios.post(`${serverUrl}/check/insertMasterSchema`, {
        codice: newCodice,
        blocco1: blocchi
      });

      console.log('Lista salvata con successo');
      alert('Nuovo modello asset creato');
    } catch (error) {
      console.error('Errore nel salvataggio della lista:', error);
    }
  };




  //===================================================================================


  const confermaModifica = async (index) => {
    try {
      const updatedBlocchi = [...blocchi];
      await aggiornaMasterSchema();

      // Rimuovi l'elemento modificato solo se la richiesta di aggiornamento ha avuto successo
      updatedBlocchi.splice(index, 1);
      setBlocchi(updatedBlocchi);
     // setIndiceElementoModificato(null);
    } catch (error) {
      console.error('Errore nell\'aggiornamento del database:', error);
      // Altre azioni di gestione dell'errore, se necessario
    }
  };



  //=================================================================================
  const annullaModifica = () => {
   // setIndiceElementoModificato(null);
    // Altre azioni di annullamento, se necessario
  };

  //============================================================================
  const handleChangeNewCodice = (e) => {
    const valoreInput = e.target.value;
    setNewCodice(valoreInput);
    // Verifica se il campo di input è vuoto e aggiorna lo stato campoVuoto di conseguenza
    //setCampoVuoto(valoreInput === '');
    setCampoVuoto(true);
  };




  const handleCodiceChange = (event) => {
    setCodice(event.target.value);
  };



  //==================================================================================
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleChange = (index, newData) => {
    setBlocchi(prevState => {
      const updatedBlocchi = [...prevState];
      updatedBlocchi[index] = newData;
      return updatedBlocchi;
    });
  };


  //============================================================================

  return (
    <>
      <Dialog open={isDialogOpen} onClose={chiudiDialogoModifica} >
        <DialogTitle>Modifica Elemento</DialogTitle>
        <DialogContent>
        <InputLabel id="controllo">Controllo</InputLabel>
          <TextField
            name="Controllo"
            labelId="Controllo"
            value={editedData.Controllo}
            onChange={handleEditChange}
            style={{ width: '500px' }} // Imposta la larghezza al 100%

          />
          <InputLabel id="tipologia">Tipologia</InputLabel>
          <Select
           labelId="tipologia"
            value={tipologia}
            onChange={handleTipologiaChange}
            fullWidth
          >
            {_tipologia.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
          <InputLabel id="priorita">Priorità</InputLabel>
          <Select
              value={priorita}
               onChange={handlePrioritaChange}
              style={{ marginRight: '10px', padding: '5px', borderRadius: '5px' }}
            >
              {_priorita.map((type, index) => (
                <MenuItem key={index} value={type}>{type}</MenuItem>
              ))}
            </Select>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSave}>Conferma</Button>
          <Button variant="contained" onClick={handleClose}>Annulla</Button>
        </DialogActions>
      </Dialog>






      <Box m="1.5rem 2.5rem">
        <div style={{ margin: '20px' }}>
          <h3 style={{ marginBottom: '20px' }}>Creazione nuovo schema checklist</h3>
          <div>
            <h5>Carica schema esistente (opzionale)</h5>
            <InputLabel id="codice-label">Tipo asset</InputLabel>
            <Select
              value={codice}
              labelId='codice-label'
              onChange={handleCodiceChange}
              style={{ marginRight: '10px', padding: '5px', borderRadius: '5px' }}
            >
              {listaAsset.map((type, index) => (
                <MenuItem key={index} value={type}>{type}</MenuItem>
              ))}
            </Select>
            <Button
              style={{ padding: '5px 10px', borderRadius: '5px', }}

              onClick={fetchDataAsset}
              variant='contained'
              color="primary"
            >
              Ricerca asset
            </Button>
          </div>

          <div>
          <InputLabel id="codice-label">Controllo</InputLabel>
            <TextField
              type="text"
              value={controllo}
              onChange={e => setControllo(e.target.value)}
             // placeholder="Controllo"
              style={{ marginRight: '10px' }}
              fullWidth
            />
            <InputLabel id="tipo-label">Tipologia</InputLabel>
            <Select
              value={tipologia}
              onChange={handleTipologiaChange}
              style={{ marginRight: '10px', padding: '5px', borderRadius: '5px' }}
            >
              {_tipologia.map((type, index) => (
                <MenuItem key={index} value={type}>{type}</MenuItem>
              ))}
            </Select>
        
         {tipologia && (
        <TextField
          label="Priorità"
          value={priorita}
          // onChange={(event) => setPriorita(event.target.value)}
          style={{ marginRight: '10px', padding: '5px', borderRadius: '5px' }}
        />
      )}

            <Button
              style={{ padding: '5px 10px', borderRadius: '5px', }}
              //  backgroundColor: 'green', color: 'white', border: 'none' }}
              onClick={aggiungiElemento}
              variant='contained'
              color="primary"
            >
              Aggiungi Elemento
            </Button>
          </div>

          <table style={{ marginTop: '20px', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px' }}>Controllo</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Tipologia</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Priorità</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Azioni</th>
              </tr>
            </thead>
            <tbody>
              {blocchi.map((elemento, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>
                    <TextField
                      type="text"
                      value={elemento.Controllo}
                      onChange={(e) => handleChange(index, 'Controllo', e.target.value)}
                      style={{ width: '500px' }}
                    />
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>
                    <TextField
                      type="text"
                      value={elemento.Tipologia}
                      onChange={(e) => handleChange(index, 'Tipologia', e.target.value)}
                    />
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>
                    <TextField
                      type="text"
                      value={elemento.Priorità}
                      style={{ width: '120px' }}
                      onChange={(e) => handleChange(index, 'Priorità', e.target.value)}
                    />
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>
                    <Button
                      variant='contained'
                      color="secondary"
                      style={{ marginRight: '5px', padding: '5px 10px', borderRadius: '5px', }}

                      onClick={() => eliminaElemento(index)}
                    >
                      Elimina
                    </Button>

                    <Button
                      style={{ padding: '5px 10px', borderRadius: '5px', }}
                      onClick={() => apriDialogoModifica(index)}
                      variant='contained'
                      color="primary"
                    >
                      Modifica
                    </Button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div>
            <h5 style={{ marginTop: '20px' }}>Denominazione nuovo asset</h5>
            <TextField
              type="text"
              value={newCodice}
              onChange={handleChangeNewCodice}
              placeholder="Denominazione asset"
              style={{ marginBottom: '10px' }}
            />
            <Button
              variant='contained'
              color="primary"
              style={{ marginLeft: '10px', marginTop: '10px', }}
              onClick={salvaLista}
            >
              Salva nuova lista
            </Button>
            {!campoVuoto && <p style={{ color: 'red' }}>Il campo non può essere vuoto</p>}
          </div>
        </div>
      </Box>
    </>
  );
}
export default CreaNewSchema;
