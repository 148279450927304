import { useState, useEffect } from "react";


//calculateRange: determina il numero di pagine
const calculateRange = (data, rowsPerPage) => {
    const range = [];
    const num = Math.ceil(data.length / rowsPerPage);

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }
    return range;
  };
  
  // sliceData: suddivide gli elementi in porzioni 
  // sulla base del numero di pagine
  const sliceData = (data, page, rowsPerPage) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };
  

  const useTable = (data, page, rowsPerPage) => {
    const [tableRange, setTableRange] = useState([]);
    const [slice, setSlice] = useState([]);


    useEffect(() => {
        const range = calculateRange(data, rowsPerPage);
        setTableRange([...range]);
    
        const slice = sliceData(data, page, rowsPerPage);
        setSlice([...slice]);
      }, [data, setTableRange, page, setSlice,rowsPerPage]);
    
      return { slice, range: tableRange };
    };
   
      
     
  
    
  
  
  export default useTable;