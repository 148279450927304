import { useState , useEffect} from "react";

import Button from '@mui/material/Button';
import axios from 'axios';
import { FormControl, Select, MenuItem, InputLabel} from '@mui/material';

const FiltroCodice = ({ onFilterChange ,  onResetFilter}) => {
 const [tickets, setTickets]=useState([]);
const [codice, setCodice]=useState('');
const [listaAsset, setListaAsset] = useState([]);
const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
const [selectedMatricola, setSelectedMatricola] = useState('');
const [selectedAsset, setSelectedAsset] = useState(null);



const fetchDataAllAsset = async () => {
  try {
   // matricola="FA315"
   const serverUrl =process.env.REACT_APP_SERVER_URL;
    const response = await axios.get(`${serverUrl}/assets/`);
    if (response.status === 200) {
      const data = response.data;
   //   console.log(response.data);
      setListaAsset(data);
  //    console.log(listaAsset);
        } else {
      console.error('Errore nella richiesta HTTP');
    }
  } catch (error) {
    console.error('Errore nella richiesta HTTP:', error);
  }
};

//====================================================================

useEffect(() => {
  fetchDataAllAsset();
}, []);

 // Effetto per caricare i dati dell'asset selezionato quando selectedMatricola cambia
 useEffect(() => {
  const asset = listaAsset.find((item) => item.matricola === selectedMatricola);
  setSelectedAsset(asset);
}, [selectedMatricola, listaAsset]);


const handleResetFiltro=() =>{
 onResetFilter()
}

const handleChangeCodice=(e) =>
{
  setCodice(e.target.value);
}

useEffect(() => {
  fetchDataAllAsset();
}, []);

useEffect(() => {
  if (codice !== '') {
    // Qui inserisci la logica per registrare il valore su db
    console.log('Codice selezionato:', codice);
  }
}, [codice]); // 

useEffect(() => {
    // Questo effetto collaterale si attiva ogni volta che 'tickets' viene modificato
    console.log(tickets);
}, [tickets]); // Dipendenza: si attiva solo quando 'tickets' cambia

const handleOnClick = () => {
   const serverUrl =process.env.REACT_APP_SERVER_URL;
    fetch(`${serverUrl}/tickets/byCode/${selectedMatricola}`)
    .then(response => {
      if (response.status === 400) {
        alert('Non trovo dati nel DB corrispondenti alla ricerca!')
        throw new Error('No data found for the provided parameters');
      }
      return response.json();
    })
      .then(data => {
        // Aggiorna lo stato nel componente principale
        onFilterChange(data);
      })
      .catch(error => console.error('Error:', error));
  }

  const handleMatricolaChange = (event) => {
    setSelectedMatricola(event.target.value);
  };


 
 

  return (
    <div className="MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
      <div className="MuiFormControl-root" >
      <FormControl style={{ marginRight: '10px', width: '200px',  marginBottom: '10px'}}>
      <InputLabel id="matricola-label">Matricola</InputLabel>
        <Select
          labelId="matricola-label"
          value={selectedMatricola}
          onChange={handleMatricolaChange}
          style={{ padding: '5px', borderRadius: '5px' }}
          fullWidth
        >
    <MenuItem value="">Seleziona una matricola</MenuItem>
    {listaAsset.map((asset) => (
      <MenuItem key={asset._id} value={asset.matricola}>
        {asset.matricola}
      </MenuItem>
    ))}
  </Select>
</FormControl>
      </div>
      {/* Pulsanti "Reset filtro" e "Avvia ricerca" sulla riga sottostante */}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <Button
          onClick={handleResetFiltro}
          size="small"
          variant="contained"
          color="primary"
          style={{ marginRight: '10px' }} // Aggiunto margine per separare i pulsanti
        >
          Reset filtro
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedMatricola}
          onClick={handleOnClick}
        >
          Avvia ricerca
        </Button>
      </div>
    </div>
  );
  
  
}
export default FiltroCodice