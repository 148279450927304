import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTheme } from "@mui/material";
import ConfirmDialog from "components/ConfirmDialog";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const UsersManager = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState({
    email: '',
    nome: '',
    cognome: '',
    userName: '',
    password: '',
    role: '',
    isActive: true,
  });
  const [editingUserId, setEditingUserId] = useState(null); // ID dell' utente in fase di modifica
  const [deletingUserId, setDeletingUserId] = useState(null); // ID utente da cancellare

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    // Carica tutti gli utenti all'avvio
    getAllUsers();
  }, []);

  // gestione cambiamento di stato con formControl
  const handleInputChange = (fieldName, value) => {
    setNewUser({ ...newUser, [fieldName]: value });
  };
  //===========================================================

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${serverUrl}/users/getusers`);
      setUsers(response.data);
    } catch (error) {
      console.error('Errore durante il recupero degli utenti:', error);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`${serverUrl}/users/cancellaUtente/${id}`);
      getAllUsers();
    } catch (error) {
      console.error('Errore durante la cancellazione dell\'utente:', error);
    }
  };

  // Gestione cancellazione con dialogo di Alert
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);

  const handleOpenConfirmDialog = (userId) => {
    setOpenConfirmDialog(true);
    setDeletingUserId(userId); // imposta id utente da cancellare
  };

  const handleCloseConfirmDialog = ({ type }) => {
    if (type === "yes") {
      alert('Codice utente in cancellazione:' + deletingUserId);
      deleteUser(deletingUserId);
    }
    setOpenConfirmDialog(false);
  };

  const theme = useTheme();

  return (
    <Box m="1.5rem 2.5rem">
      <h1>Gestione utenti</h1>
      <div>
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          deletingUserId={deletingUserId}
        />
        <h2>Lista degli Utenti</h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#55C1F3E0' }}>
                <TableCell style={{ fontSize: '0.9rem' }}>Nome utente</TableCell>
                <TableCell style={{ fontSize: '0.9rem' }}>Ruolo</TableCell>
                <TableCell style={{ fontSize: '0.9rem' }}>Email</TableCell>
                <TableCell style={{ fontSize: '0.9rem' }}>Nome</TableCell>
                <TableCell style={{ fontSize: '0.9rem' }}>Cognome</TableCell>
                <TableCell style={{ fontSize: '0.9rem' }}>Azioni</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user._id} style={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell style={{ fontSize: '0.9rem' }}>{user.userName}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{user.role}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>
                            {user.email.startsWith('default_') ? '' : user.email}
                  </TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{user.nome}</TableCell>
                  <TableCell style={{ fontSize: '0.9rem' }}>{user.cognome}</TableCell>
                  <TableCell>
                    {/* <Button variant="contained" color="primary" onClick={() => edituser(user)}>Modifica</Button> */}
                    <Button variant="contained" color="secondary" onClick={() => handleOpenConfirmDialog(user._id)}>Elimina</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default UsersManager;

