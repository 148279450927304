import React, { useState, useEffect } from 'react';
// import ListaTicketsAperti from './ListaTicketsAperti';
import axios from 'axios';
import CheckListTable from './checkListP1';

import {Box}  from "@mui/material";
import OpenListTickets from './openListTickets';
// import SelectElement from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { Button, Container, Typography, TextField, Select} from '@mui/material';


const headerStyle = {
  backgroundColor: '#007bff',
  color: 'white',
};

// stili tabella elementi checklist
const cellStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'center',
};


//====================================================================

const GetCheck2 = () => {
  const [listaAsset, setListaAsset] = useState([]);
  const [codiceAsset, setCodiceAsset] = useState(''); 
  const [d1, setData1] = useState(); 
  const [d2, setData2] = useState(); 
  const [selectedRow, setSelectedRow] = useState(null);

  const[checkList, setCheckList] =useState([])
  // const [ticketsAperti, setTicketsAperti] = useState(0);
  const [listaTicketsAperti, setListaTicketsAperti] = useState([]);
  // const [progressivo, setProgressivo] = useState('');

  const [selectedMatricola, setSelectedMatricola] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [ticket, setTicket] = useState( {});
  const serverUrl =process.env.REACT_APP_SERVER_URL;




 // funzione di callBack per gestire la selezione nel figlio
  const handleRowSelect = (index) => {
    console.log(checkList);
   
    setSelectedRow(index);
   

  };


  //=================================================================

  const handleOnchangeData1=(e) =>
  {
    setData1(e.target.value);
  }

  const handleOnchangeData2=(e) =>
  {
    setData2(e.target.value);
  }

  // Ricavo lista asset da presentare nel combo di selezione
  const fetchDataAllAsset = async () => {
    try {
     // matricola="FA315"
   
      const response = await axios.get(`${serverUrl}/assets/`);
      if (response.status === 200) {
        const data = response.data;
     //   console.log(response.data);
        setListaAsset(data);
    //    console.log(listaAsset);
          } else {
        console.error('Errore nella richiesta HTTP');
      }
    } catch (error) {
      console.error('Errore nella richiesta HTTP:', error);
    }
  };

  //====================================================================

  useEffect(() => {
    fetchDataAllAsset();
  }, []);

   // Effetto per caricare i dati dell'asset selezionato quando selectedMatricola cambia
   useEffect(() => {
    const asset = listaAsset.find((item) => item.matricola === selectedMatricola);
    setSelectedAsset(asset);
  }, [selectedMatricola, listaAsset]);

  //===============================================================================================
  // funzione modificata per gestire il doppio pulsante di filtraggio  (ultima check / data+matricola)
  const fetchDataCheckList = async (tipo) => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    // svuoto l'array con i risultati precedenti...
    // setCheckList([]); ??????
    if (selectedMatricola === "" || d1 === "" || d2==="") {
      if (tipo ===2 )  alert("Seleziona una matricola e inserisci le date prima di avviare la ricerca.");
      if (tipo ===1 )  alert("Seleziona una matricola prima di avviare la ricerca.");
      return; // Interrompe l'esecuzione della funzione
    }
    try {
      let response;
      if (tipo===1) {
        // Utilizza l'endpoint che restituisce un oggetto di tipo checkList
        response = await fetch(`${serverUrl}/check/getLastCheckList/${selectedMatricola}`);
      } else {
        // Utilizza l'endpoint che restituisce un array di checkList
        console.log(d1);
        console.log(d2);
        console.log(selectedMatricola);
        response = await fetch(`${serverUrl}/check/getCheckByDataMatricola/${d1}/${d2}/${selectedMatricola}`);
      }
      
      if (response.ok) {
        const jsonData = await response.json();
        console.log(jsonData);
        if (Array.isArray(jsonData)) {
          // Se l'array di checkList è vuoto, mostra un avviso
          if (jsonData.length === 0) {
            alert("Nessun risultato trovato.");
            return;
          }
          // Aggiungi ogni checkList all'array checkList
          setCheckList(jsonData);
        
        } else {
          // Se è restituito un singolo oggetto checkList, aggiungilo a un array prima di impostarlo come stato checkList
          setCheckList([jsonData]);
        }
           // Ripristina il valore selezionato nella select allo stato iniziale
      setSelectedMatricola("");
      } else if (response.status === 404) {
        alert(`Non trovo nessuna checkList corrispondente a: ${selectedMatricola}`);
        setSelectedMatricola("");
      } else {
        console.error(`Errore nella richiesta HTTP: ${response.status}`);
      }
    } catch (error) {
      console.error("Errore durante la richiesta:", error.message);
    }
  };

//=================================================================================== 
   //===============================================================================
   const handleMatricolaChange = (event) => {
    setSelectedMatricola(event.target.value);
  };

  //======================================================================
// Funzione per ottenere un ticket dato l'IdTicket
async function getTicketByIdTicket(idTicket) {
//  alert(' Sono entrato...'+idTicket);
  const getUrl=`${serverUrl}/tickets/leggi/${idTicket}`;
//  alert("sono in fetch "+ getUrl);
  try {
    const response = await axios.get(getUrl);
    console.log('Risposta dalla chiamata axios:', response);
    setTicket(response.data);
    console.log(response.data);
    console.log(ticket);
    return response.data;
  } catch (error) {
    console.error('Errore durante la chiamata axios:', error);
    throw error; // Rilancia l'errore per gestirlo al livello superiore
  }
}



async function fetchTickets(listaFromDb) {
  const listaTickets = [];
  const listaIdTicket = listaFromDb;
  console.log(listaIdTicket);
  for (const idTicket of listaIdTicket) {
    try {
      const ticket = await getTicketByIdTicket(idTicket); 
      listaTickets.push(ticket);
    } catch (error) {
      console.error(`Errore durante il recupero del ticket con ID ${idTicket}:`, error);
    }
  }

  console.log("listaTickets:", listaTickets);
  setListaTicketsAperti(listaTickets);
  return listaTickets;
}

// ============================================


  

  console.log(checkList);



  //   AVVIO RENDERIZZAZIONE  
  return (<>
 <Box m="1.5rem 2.5rem">
<h2 style={{ marginBottom: '10px' }}>Consultazione checkList</h2>
<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
 
<Typography variant="h5" component="h5"  style={{ marginRight: '10px',}}>Matricola:</Typography>
   
    <Select 
  labelId="selectedMatricola"
  label="Matricola"
  name="Matricola"
  value={selectedMatricola}
  onChange={handleMatricolaChange}
  style={{ marginRight: '10px',}}
>
  <MenuItem value="">
    <em>Seleziona una matricola</em>
  </MenuItem>
  {listaAsset.map((asset) => (
    <MenuItem key={asset._id} value={asset.matricola}>
      {asset.matricola}
    </MenuItem>
  ))}
</Select>
     
  <Button
    variant="contained"
  //  style={{ leftMargin: '10px', padding: '5px 10px', borderRadius: '5px', backgroundColor: 'blue', color: 'white', border: 'none' }}
    onClick={()=>fetchDataCheckList(1)}
  >
    Ultima checklist data matricola
  </Button>
</div>
<div>
{selectedAsset && (
  <div>
    <h3>Dati dell'asset selezionato:</h3>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ marginRight: '20px' }}>
        <p>Tipo: {selectedAsset.codice}</p>
        <p>Denominazione: {selectedAsset.nome}</p>
        <p>Matricola: {selectedAsset.matricola}</p>
      </div>
      <div>
        <p>Acquisto: {selectedAsset.data_acquisto}</p>
        <p>Immatricolazione: {selectedAsset.data_immatricolazione}</p>
        <p>Scadenza assicurazione: {selectedAsset.data_scadenza_assicurazione}</p>
      </div>
    </div>
  </div>
)}
    </div>

<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
  <TextField
    label="Dal:"
    type="date"
    name="d1"
    id="d1"
    onChange={handleOnchangeData1}
    value={d1}
    InputLabelProps={{
      shrink: true,
    }}
    style={{ marginRight: '10px' }}
  />
    <TextField
    label="Al:"
    type="date"
    name="d2"
    id="d2"
    onChange={handleOnchangeData2}
    value={d2}
    InputLabelProps={{
      shrink: true,
    }}
    style={{ marginRight: '10px' }}
  />
  <Button
   variant = "contained"
    onClick={()=>fetchDataCheckList(2)}
  >
    Avvia ricerca per matricola e data
  </Button>
</div>

<div>
      <h1>Elenco checklist</h1>
      <table style={{ marginTop: '20px', borderCollapse: 'collapse', width: '100%' }}>
  <thead>
    <tr style={{backgroundColor: 'cyan', color: 'blue', alignItems: "center"}}>
      <th style={{ border: '1px solid black', padding: '8px'  }}>Asset</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Data</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Matricola</th>
     
      <th style={{ border: '1px solid black', padding: '8px' }}>Operatore</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Progressivo</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Ticket aperti</th>
      <th style={{ border: '1px solid black', padding: '8px' }}>Seleziona</th></tr>
    </thead>

      {checkList.map((item, index) => (
      
       
      <CheckListTable 
          checkList={item} key={index} 
          ticketsAperti={item.listaIdTicket}
          index= {index}
          handleRowSelect = {() => handleRowSelect(index)}
         
          />
      ))
} </table>


      {selectedRow !== null && (
        <div>
    <h4 style={{ textAlign: "center"}}>Elementi controllati</h4>
        <table style={{ marginTop: '2px', borderCollapse: 'collapse', width: '100%' }}>
         <thead>
          <tr style={headerStyle}>
          <th style={{ border: '1px solid black', padding: '8px' }}>Denominazione</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Stato</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Tipologia</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Priorità</th>
          <th style={{ border: '1px solid black', padding: '8px' }}>Note</th>
      
      </tr>
   </thead>
  <tbody>
    {checkList[selectedRow] && checkList[selectedRow].datiVariabili.map((item, index) => (
                  <tr key={index} style={{color: item.Stato==="Ko" ? 'red': 'white'}}>
                  <td style={cellStyle}>{item.Controllo}</td>
                  <td style={cellStyle}>{item.Stato}</td>
                  <td style={cellStyle}>{item.Tipologia}</td>
                  <td style={cellStyle}>{item.Priorità}</td>
                  <td style={cellStyle}>{item.Note}</td>
                </tr>
              ))}
            </tbody>
         
          </table>
          
        {checkList[selectedRow] && checkList[selectedRow].listaIdTicket.length > 0 && (
            <div>
         
                <OpenListTickets listaIdTicket={checkList[selectedRow].listaIdTicket} />
        </div>
      )}
        </div>
      )}
    </div>

    </Box>
    
    </>
  );
};

export default GetCheck2;
