// conteggio tickets sulla base delle priorità 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';

const barPercentage = 0.5;
const backgroundColor = ['lightgreen', 'moccasin', 'crimson'];

const options = {
  legend: { display: false },
  maintainAspectRatio: false,
  responsive: false,
  skipNull:true,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  }
}

const ChartPriorita = () => {
  const [tickets, setTickets] = useState([]);
  const [data, setData] = useState({
     labels: ['Bassa', 'Media', 'Alta'],
     
    datasets: [{
      data: [0, 0, 0],
      barPercentage: barPercentage,
      backgroundColor: backgroundColor,
      label:''
     
    }]
  });

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    axios.get(`${serverUrl}/tickets`)
      .then(res => {
        setTickets(res.data);
        let low = 0;
        let medium = 0;
        let high = 0;

        res.data.forEach(ticket => {
          if (ticket.status==='cancellato')
            switch (ticket.priority) {
              case 'Bassa':
              case 'bassa':
                low--;
                break;
              case 'Media':
              case 'media':
                medium--;
                break;
              case 'Alta':
              case 'alta':
                high--;
                break;
              default:
                break;
            }

          if (ticket.status !== 'Risolto') {
            switch (ticket.priority) {
              case 'Bassa':
              case 'bassa':
                low++;
                break;
              case 'Media':
              case 'media':
                medium++;
                break;
              case 'Alta':
              case 'alta':
                high++;
                break;
              default:
                break;
            }
          }
        });

        setData({
          ...data,      
          datasets: [{
            ...data.datasets[0],
            data: [low, medium, high]
          }]
        });
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div style={{ backgroundColor: 'lightgray', height:350 }}>
      <Bar
        data={data}
        options={options}
        height={350}
        width={500} />
    </div>
  );
};

export default ChartPriorita;
