//modifica Maggio 24: conteggio tickets aperti/scaduti
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';

const backgroundColor = ['aquamarine', 'lightcoral'];
const labels = ['Aperti', 'Scaduti'];
const options = {
  maintainAspectRatio: false,
  responsive: false,
};

const ChartTipo = () => {
  const [tickets, setTickets] = useState([]);
  const [data, setData] = useState({
    labels: labels,
    datasets: [{
      data: [0, 0],
      backgroundColor: backgroundColor
    }]
  });

  const today = new Date(); // Ottieni la data corrente

  useEffect(() => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    axios.get(`${serverUrl}/tickets/`)
      .then(res => {
        setTickets(res.data);
        let open = 0;
        let scaduti = 0;
        let cancellati=0;
       
      // console.log(res.data);
        res.data.forEach(ticket => {
          if (ticket.status.toLowerCase()==="cancellato") {cancellati++;}
          if (ticket.status.toLowerCase()==="open") {
               //  open++;
               // conteggio scaduti
               // Verifica se il ticket è scaduto
              const scadenzaTicket = new Date(ticket.scade);
           //   console.log(scadenzaTicket) ;
           //   console.log(today);// Supponendo che la data di scadenza sia nel formato corretto
             if (scadenzaTicket < today) { scaduti++; // Incrementa il contatore dei ticket scaduti
                          } else {open++}
          }
        });

        setData({
          ...data,
          datasets: [{
            ...data.datasets[0],
            data: [open, scaduti]
          }]
        });
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <div style={{ backgroundColor: 'lightgray', height:350 }}>
      <Doughnut 
        data={data}
        options={options}
        height={300}
        width={500} />
    </div>
  );
};

export default ChartTipo;
